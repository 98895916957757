import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

import { environment } from '../environments/environment';

import { selectMainUser } from './collections/users/store/users.selectors';
import { ErrorDetailsEntity } from './models/backendModel';

const callsInBackground = {
  DELETE: [
    /projects\/\d*\/currencies\/\d*$/i,
    /scenarios\/\d*$/i,
    /scenarios\/\d*\/ctr\/\d*$/i,
    /scenarios\/\d*\/details$/i,
    /scenarioctrs\/\d*\/equipment\/\d*$/i,
    /scenarioctrs\/scope\/mancpx\/\d*$/i,
  ],
  PUT: [
    /scenarios\/\d*$/i,
    /scenarios\/\d*\/details$/i,
    /scenarios\/\d*\/description$/i,
    /scenarioctrs\/\d*\/equipment\/\d*$/i,
    /scenarioctrs\/\d*\/timetable$/i,
    /scenarioctrs\/\d*\/scope$/i,
    /scenarioctrs\/scope\/\d*\/assumptions/i,
    /scenarioctrs\/scope\/\d*\/equipment\/\d*\/assumptions/i,
    /scenarioctrs\/scope\/\d*\/exclusions/i,
    /scenarioctrs\/scope\/\d*\/equipment\/\d*\/exclusions/i,
    /scenarioctrs\/scope\/mancpx\/\d*$/i,
    /scenarioctrs\/scope\/mancpx\/\d*\/equipment$/i,
    /scenarioctrs\/scope\/\d*\/stdcpx/i,
    /scenarioctrs\/\d*\/sctrinputs$/i,
    /scenarioctrs\/\d*\/assigns$/i,
    /scenarioctrs\/\d*\/assign\/\d*\/comment$/i,
  ],
  POST: [
    /\d*\/ctr\/\d*$/i,
    /scenarioctrs\/\d*\/equipment$/i,
    /scenarioctrs\/scope\/\d*\/mancpx$/i,
    /Mediator\/SaveWorkloadDistributionForRolesCommand$/g,
  ],
  GET: [
    /scenarioctrs\/\d*\/equipment/i,
    /scenarioctrs\/\d*\/sctrtotalhours/i,
    /scenarioctrs\/\d*\/navbardetails.*/i,
    /scenarioctrs\/\d*\/build\/scenarioBlocks.*/i,
  ],
};

@Injectable({ providedIn: 'root' })
export class AppService implements HttpInterceptor {
  /** @deprecated */
  public readonly isLoaderVisible = new BehaviorSubject<boolean>(false);

  /** @deprecated */
  public readonly spinnerDisplay = new BehaviorSubject<boolean>(false);

  private readonly requests: HttpRequest<unknown>[] = [];

  private readonly currentUser$ = this.store
    .select(selectCurrentInitializedUser)
    .pipe(startWith(null), shareReplay(1));

  private readonly mainUser$ = this.store
    .select(selectMainUser)
    .pipe(startWith(null), shareReplay(1));

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly store: Store,
    private readonly snackBar: MatSnackBar
  ) {}

  removeRequest(req: HttpRequest<unknown>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
      if (this.requests.length == 0) {
        this.isLoaderVisible.next(false);
        this.spinnerDisplay.next(false);
      }
    }
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return combineLatest([this.currentUser$, this.mainUser$]).pipe(
      take(1),
      switchMap(([currentUser, mainUser]) => {
        if (!this.shouldRunInBackground(req)) {
          this.requests.push(req);
          this.isLoaderVisible.next(true);
          this.spinnerDisplay.next(true);
        }
        const clonedRequest = req.clone(
          currentUser?.email
            ? {
                headers: req.headers.set('userid', currentUser.email),
              }
            : {}
        );

        return next.handle(clonedRequest).pipe(
          finalize(() => this.removeRequest(req)),
          catchError((err) => this.handleError(err, clonedRequest, mainUser))
        );
      })
    );
  }

  public handleError(
    error,
    req: HttpRequest<unknown>,
    mainUser
  ): Observable<never> {
    const errMsg =
      error.error ||
      error.message ||
      error._body ||
      environment.genericErrorMessage;
    this.spinnerDisplay.next(false);
    this.isLoaderVisible.next(false);

    console.error({ req, error });

    if (error.error instanceof Blob) {
      return new Observable<never>((observer) => {
        const reader = new FileReader();
        reader.onload = (): void => {
          const errorJson = JSON.parse(reader.result as string);
          let result = errorJson as ErrorDetailsEntity;
          if (!result) {
            result = { errorMessage: null } as ErrorDetailsEntity;
          }
          if (!result?.errorMessage) {
            result.errorMessage = errorJson?.ErrorMessage;
          }

          this.processError(result, error, req, mainUser);
          observer.error(result);
        };
        reader.onerror = (): void => {
          observer.error(errMsg);
        };
        reader.readAsText(error.error);
      });
    } else {
      let result = error?.error as ErrorDetailsEntity;
      if (!result) {
        result = { errorMessage: null } as ErrorDetailsEntity;
      }
      if (!result?.errorMessage) {
        result.errorMessage = error?.error?.ErrorMessage;
      }

      this.processError(result, error, req, mainUser);
      return throwError(() => errMsg);
    }
  }

  private processError(
    result: ErrorDetailsEntity,
    error,
    req: HttpRequest<unknown>,
    mainUser
  ): void {
    switch (true) {
      case error.status === HttpStatusCode.NotFound:
        throwError(() => error);
        break;
      case error.status === HttpStatusCode.Forbidden:
        this.router.navigate(['register']);
        break;
      case !error.error ||
        error.status === HttpStatusCode.InternalServerError ||
        error.status === 0:
        this.showNotification(
          `Unexpected error occurred while requesting ${req.method} ${req.url}, please contact system administrator`
        );
        break;
      case error.status === HttpStatusCode.BadRequest ||
        error.error.ErrorCode === HttpStatusCode.BadRequest:
        this.showNotification(`An error occurred : ${result?.errorMessage}`);
        break;
      case error.status !== HttpStatusCode.Ok:
        if (mainUser?.isAdmin) {
          this.showNotification(error?.errorMessage);
        }
        break;
    }
  }

  public showNotification(message: string): void {
    this.snackBar.open(message, null, {
      duration: 10000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  private shouldRunInBackground(request: HttpRequest<unknown>): boolean {
    const requestMethod = request.method;
    const requestUrl = request.url;
    return !!callsInBackground[requestMethod]?.find((regexp: RegExp) =>
      requestUrl.match(regexp)
    );
  }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  public post<T>(url: string, body): Observable<T> {
    return this.http.post<T>(url, body);
  }

  public put<T>(url: string, body): Observable<T> {
    return this.http.put<T>(url, body);
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }
}

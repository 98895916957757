import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  distinct,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { selectDetailedPxDFactory } from '@collections/pxds/store/pxds.selectors';
import {
  selectCurrentBusinessSegmentId,
  selectCurrentInitializedUser,
} from '@collections/users/store/users.selectors';

import { PxDsApiService } from '../pxds-api.service';
import { PxDsService } from '../pxds.service';

import {
  createDisciplineAction,
  createDisciplineFailureAction,
  createDisciplineSuccessAction,
  createProductAction,
  createProductFailureAction,
  createProductSuccessAction,
  deleteDisciplineAction,
  deleteDisciplineFailureAction,
  deleteDisciplineSuccessAction,
  deleteProductAction,
  deleteProductFailureAction,
  deleteProductSuccessAction,
  editDisciplineAction,
  editDisciplineFailureAction,
  editDisciplineSuccessAction,
  editProductAction,
  editProductFailureAction,
  editProductSuccessAction,
  editPxDAction,
  editPxDFailureAction,
  editPxDSuccessAction,
  getDetailedPxDAction,
  getDetailedPxDFailureAction,
  getDetailedPxDSuccessAction,
  getMinifiedPxDsAction,
  getMinifiedPxDsFailureAction,
  getMinifiedPxDsSuccessAction,
  getProductsAndDisciplinesAction,
  getProductsAndDisciplinesFailureAction,
  getProductsAndDisciplinesSuccessAction,
  getPxDsPrincipalsAction,
  getPxDsPrincipalsFailureAction,
  getPxDsPrincipalsSuccessAction,
  openCreateDisciplineAction,
  openCreateProductAction,
  openEditDisciplineAction,
  openEditProductAction,
  openReorderPxDsAction,
  updatePxDsOrderAction,
  updatePxDsOrderFailureAction,
  updatePxDsOrderSuccessAction,
} from './pxds.actions';

@Injectable()
export class PxDsEffects {
  public getMinifiedPxDs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMinifiedPxDsAction),
      switchMap((action) =>
        this.pxdsApiService
          .getMinifiedPxDs(action.payload.businessSegmentId)
          .pipe(
            map((payload) =>
              getMinifiedPxDsSuccessAction({
                trigger: action,
                context: 'PxDsEffects::getMinifiedPxDs$',
                payload,
              })
            ),
            catchError(() =>
              of(
                getMinifiedPxDsFailureAction({
                  trigger: action,
                  context: 'PxDsEffects::getMinifiedPxDs$',
                })
              )
            )
          )
      )
    )
  );

  public getPxDsPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPxDsPrincipalsAction),
      switchMap((action) =>
        this.pxdsApiService
          .getPxDsPrincipals(action.payload.businessSegmentId)
          .pipe(
            map((payload) =>
              getPxDsPrincipalsSuccessAction({
                trigger: action,
                context: 'PxDsEffects::getPxDsPrincipals$',
                payload,
              })
            ),
            catchError(() =>
              of(
                getPxDsPrincipalsFailureAction({
                  trigger: action,
                  context: 'PxDsEffects::getPxDsPrincipals$',
                })
              )
            )
          )
      )
    )
  );

  public getProductsAndDisciplines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsAndDisciplinesAction),
      switchMap((action) =>
        this.pxdsApiService
          .getProductsAndDisciplines(action.payload.businessSegmentId)
          .pipe(
            map((payload) =>
              getProductsAndDisciplinesSuccessAction({
                trigger: action,
                context: 'PxDsEffects::getProductsAndDisciplines$',
                payload: {
                  ...payload,
                  businessSegmentId: action.payload.businessSegmentId,
                },
              })
            ),
            catchError(() =>
              of(
                getProductsAndDisciplinesFailureAction({
                  trigger: action,
                  context: 'PxDsEffects::getProductsAndDisciplines$',
                })
              )
            )
          )
      )
    )
  );

  public initPxDsAutoCompleteData$ = createEffect(() =>
    this.store.select(selectCurrentBusinessSegmentId).pipe(
      filter((v) => !!v),
      distinct(),
      map((businessSegmentId) =>
        getMinifiedPxDsAction({
          context: 'PxDsEffects::initPxDsAutoCompleteData$',
          payload: { businessSegmentId },
        })
      )
    )
  );

  public initProductsAndDisciplinesData$ = createEffect(() =>
    this.store.select(selectCurrentInitializedUser).pipe(
      filter((user) => !!user),
      take(1),
      switchMap(() => this.store.select(selectCurrentBusinessSegmentId)),
      distinct(),
      switchMap((businessSegmentId) => [
        getProductsAndDisciplinesAction({
          context: 'PxDsEffects::initCurrenciesData$',
          payload: { businessSegmentId },
        }),
      ])
    )
  );

  public openReorderPxDsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openReorderPxDsAction),
      switchMap((action) =>
        this.pxdsService.openReorderPxDs(action.payload.businessSegmentId).pipe(
          map(({ products, disciplines }) =>
            updatePxDsOrderAction({
              trigger: action,
              context: 'PxDsEffects::openReorderPxDsDialog$',
              payload: {
                businessSegmentId: action.payload.businessSegmentId,
                products,
                disciplines,
              },
            })
          )
        )
      )
    )
  );

  public updatePxDsOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePxDsOrderAction),
      mergeMap((action) =>
        this.pxdsApiService
          .patchPxDsOrder(
            action.payload.businessSegmentId,
            action.payload.products,
            action.payload.disciplines
          )
          .pipe(
            map((payload) =>
              updatePxDsOrderSuccessAction({
                trigger: action,
                context: 'PxDsEffects::updatePxDsOrder$',
                payload,
              })
            ),
            catchError(() =>
              of(
                updatePxDsOrderFailureAction({
                  trigger: action,
                  context: 'PxDsEffects::updatePxDsOrder$',
                })
              )
            )
          )
      )
    )
  );

  public openCreateProductDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCreateProductAction),
      switchMap((action) =>
        this.pxdsService
          .openCreateProductDialog(action.payload.businessSegmentId)
          .pipe(
            map((newProductData) =>
              createProductAction({
                trigger: action,
                context: 'PxDsEffects::openCreateProducDialog$',
                payload: {
                  ...newProductData,
                  businessSegmentId: action.payload.businessSegmentId,
                },
              })
            )
          )
      )
    )
  );

  public openCreateDisciplineDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCreateDisciplineAction),
      switchMap((action) =>
        this.pxdsService
          .openCreateDisciplineDialog(action.payload.businessSegmentId)
          .pipe(
            map((newDisciplineData) =>
              createDisciplineAction({
                trigger: action,
                context: 'PxDsEffects::openCreateDisciplineDialog$',
                payload: {
                  ...newDisciplineData,
                  businessSegmentId: action.payload.businessSegmentId,
                },
              })
            )
          )
      )
    )
  );

  public createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProductAction),
      mergeMap((action) =>
        this.pxdsApiService.createProduct(action.payload).pipe(
          map((payload) =>
            createProductSuccessAction({
              trigger: action,
              context: 'pxDsEffects::createProduct$',
              payload: {
                ...action.payload,
                id: payload,
              },
            })
          ),
          catchError(() =>
            of(
              createProductFailureAction({
                trigger: action,
                context: 'PxDsEffects::createProduct$',
              })
            )
          )
        )
      )
    )
  );

  public createDiscipline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDisciplineAction),
      mergeMap((action) =>
        this.pxdsApiService.createDiscipline(action.payload).pipe(
          map((payload) =>
            createDisciplineSuccessAction({
              trigger: action,
              context: 'PxDsEffects::createDiscipline$',
              payload: {
                ...action.payload,
                id: payload,
              },
            })
          ),
          catchError(() =>
            of(
              createDisciplineFailureAction({
                trigger: action,
                context: 'PxDsEffects::createDiscipline$',
              })
            )
          )
        )
      )
    )
  );

  public openEditProductDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEditProductAction),
      switchMap((action) =>
        this.pxdsService
          .openEditProductDialog(
            action.payload.productId,
            action.payload.businessSegmentId
          )
          .pipe(
            map((editedProductData) =>
              editedProductData?.deleted
                ? deleteProductAction({
                    trigger: action,
                    context: 'PxDsEffects::openEditProductDialog$',
                    payload: {
                      productId: action.payload.productId,
                      businessSegmentId: action.payload.businessSegmentId,
                    },
                  })
                : editProductAction({
                    trigger: action,
                    context: 'PxDsEffects::openEditProductDialog$',
                    payload: {
                      ...editedProductData,
                      id: action.payload.productId,
                      businessSegmentId: action.payload.businessSegmentId,
                    },
                  })
            )
          )
      )
    )
  );

  public editProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editProductAction),
      switchMap((action) =>
        this.pxdsApiService.editProduct(action.payload).pipe(
          map(() =>
            editProductSuccessAction({
              trigger: action,
              context: 'PxDsEffects::editProduct$',
              payload: action.payload,
            })
          ),
          tap(() => this.pxdsService.showNotification('Product updated')),
          catchError(() =>
            of(
              editProductFailureAction({
                trigger: action,
                context: 'PxDsEffects::editProduct$',
              })
            )
          )
        )
      )
    )
  );

  public deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProductAction),
      switchMap((action) =>
        this.pxdsApiService.deleteProduct(action.payload.productId).pipe(
          map(() =>
            deleteProductSuccessAction({
              trigger: action,
              context: 'PxDsEffects::deleteProduct$',
              payload: action.payload,
            })
          ),
          tap(() => this.pxdsService.showNotification('Product deleted')),
          catchError(() =>
            of(
              deleteProductFailureAction({
                trigger: action,
                context: 'PxDsEffects::deleteProduct$',
              })
            )
          )
        )
      )
    )
  );

  public openEditDisciplineDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEditDisciplineAction),
      switchMap((action) =>
        this.pxdsService
          .openEditDisciplineDialog(
            action.payload.disciplineId,
            action.payload.businessSegmentId
          )
          .pipe(
            map((editedDisciplineData) =>
              editedDisciplineData?.deleted
                ? deleteDisciplineAction({
                    trigger: action,
                    context: 'PxDsEffects::openEditDisciplineDialog$',
                    payload: {
                      disciplineId: action.payload.disciplineId,
                      businessSegmentId: action.payload.businessSegmentId,
                    },
                  })
                : editDisciplineAction({
                    trigger: action,
                    context: 'PxDsEffects::openEditDisciplineDialog$',
                    payload: {
                      ...editedDisciplineData,
                      id: action.payload.disciplineId,
                      businessSegmentId: action.payload.businessSegmentId,
                    },
                  })
            )
          )
      )
    )
  );

  public editDiscipline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editDisciplineAction),
      switchMap((action) =>
        this.pxdsApiService.editDiscipline(action.payload).pipe(
          map(() =>
            editDisciplineSuccessAction({
              trigger: action,
              context: 'PxDsEffects::editDiscipline$',
              payload: action.payload,
            })
          ),
          tap(() => this.pxdsService.showNotification('Discipline updated')),
          catchError(() =>
            of(
              editDisciplineFailureAction({
                trigger: action,
                context: 'PxDsEffects::editDiscipline$',
              })
            )
          )
        )
      )
    )
  );

  public deleteDiscipline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDisciplineAction),
      switchMap((action) =>
        this.pxdsApiService.deleteDiscipline(action.payload.disciplineId).pipe(
          map(() =>
            deleteDisciplineSuccessAction({
              trigger: action,
              context: 'PxDsEffects::deleteDiscipline$',
              payload: action.payload,
            })
          ),
          tap(() => this.pxdsService.showNotification('Discipline deleted')),
          catchError(() =>
            of(
              deleteDisciplineFailureAction({
                trigger: action,
                context: 'PxDsEffects::deleteDiscipline$',
              })
            )
          )
        )
      )
    )
  );

  public getDetailedPxD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDetailedPxDAction),
      switchMap((action) =>
        this.store.select(selectDetailedPxDFactory(action.payload.pxdId)).pipe(
          switchMap((pxdDetailedFromStore) =>
            !pxdDetailedFromStore
              ? this.pxdsApiService.getPxDDetails(action.payload.pxdId).pipe(
                  map((pxdDetailed) =>
                    getDetailedPxDSuccessAction({
                      trigger: action,
                      context: 'PxDsEffects::getDetailedPxD$',
                      payload: { ...pxdDetailed, id: action.payload.pxdId },
                    })
                  ),
                  catchError(() =>
                    of(
                      getDetailedPxDFailureAction({
                        trigger: action,
                        context: 'PxDsEffects::getDetailedPxD$',
                      })
                    )
                  )
                )
              : EMPTY
          )
        )
      )
    )
  );

  public patchPxD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editPxDAction),
      switchMap((action) =>
        this.pxdsApiService.patchPxD(action.payload.pxd).pipe(
          map(() =>
            editPxDSuccessAction({
              trigger: action,
              context: 'PxdsEffects::editPxD$',
              payload: action.payload.pxd,
            })
          ),
          catchError(() =>
            of(
              editPxDFailureAction({
                trigger: action,
                context: 'PxdsEffects::patchPxD$',
              })
            )
          )
        )
      )
    )
  );

  public triggerReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        createProductSuccessAction,
        createDisciplineSuccessAction,
        updatePxDsOrderSuccessAction,
        editProductSuccessAction,
        editDisciplineSuccessAction,
        deleteProductSuccessAction,
        deleteDisciplineSuccessAction
      ),
      switchMap((action) => [
        getMinifiedPxDsAction({
          trigger: action,
          context: 'PxDsEffects::triggerReload$',
          payload: { businessSegmentId: action.payload.businessSegmentId },
        }),
        getProductsAndDisciplinesAction({
          trigger: action,
          context: 'PxDsEffects::triggerReload$',
          payload: { businessSegmentId: action.payload.businessSegmentId },
        }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly pxdsApiService: PxDsApiService,
    private readonly pxdsService: PxDsService,
    private readonly store: Store
  ) {}
}

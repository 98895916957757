import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  selectCurrentInitializedUser,
  selectCurrentUserIsAdmin,
  selectCurrentUserIsEngineer,
  selectCurrentUserIsRequestor,
  selectShortRoleName,
} from '@collections/users/store/users.selectors';
import { CtrStatus } from '@common/enums';
import {
  selectCurrentRouteData,
  selectCurrentRouteUserRoleIsAdmin,
  selectCurrentRouteUserRoleIsEngineer,
  selectCurrentRouteUserRoleIsRequestor,
} from '@core/store/core.selectors';
import { IScenarioGeneralInfo } from '@models/project';

import { environment } from '@environments/environment';
import {
  selectSidenavProjectConfiguration,
  selectSidenavProjectConfigurationOwn,
  selectSidenavUserIsViewer,
} from './store/sidenav.selectors';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  public sideNavbarColor = environment.sideNavbarColor;

  private readonly currentRouteData$ = this.store.select(
    selectCurrentRouteData
  );

  public projectView$ = this.currentRouteData$.pipe(
    map(({ projectView = false }) => projectView)
  );

  public showAddProjectButton$ = this.currentRouteData$.pipe(
    map(({ showAddProjectButton = false }) => showAddProjectButton)
  );

  public showNewScenario$ = this.currentRouteData$.pipe(
    map(({ showNewScenarioSidenav = false }) => showNewScenarioSidenav)
  );

  public showNewProject$ = this.currentRouteData$.pipe(
    map(({ showNewProjectSidenav = false }) => showNewProjectSidenav)
  );

  public showRequestorContext$ = this.store.select(
    selectCurrentRouteUserRoleIsRequestor
  );

  public showEngineerContext$ = this.store.select(
    selectCurrentRouteUserRoleIsEngineer
  );

  public showAdminContext$ = this.store.select(
    selectCurrentRouteUserRoleIsAdmin
  );

  public sideNavConfiguration$ = this.store.select(
    selectSidenavProjectConfiguration
  );

  public projectHeading$ = this.sideNavConfiguration$.pipe(
    map(({ projectName = '' }) =>
      projectName ? `Project: ${projectName}` : ''
    )
  );

  public currentUser$ = this.store
    .select(selectCurrentInitializedUser)
    .pipe(filter((v) => !!v));

  public isAdmin$ = this.store.select(selectCurrentUserIsAdmin);

  public userIsRequestor$ = this.store.select(selectCurrentUserIsRequestor);

  public userIsEngineer$ = this.store.select(selectCurrentUserIsEngineer);

  public shortRoleName$ = this.store.select(selectShortRoleName);

  public showOwnSCTRS = false;

  private readonly subscriptions = new Subscription();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    const userCtrRoleSubscription = this.store
      .select(selectSidenavUserIsViewer)

      .subscribe((userIsViewer) => {
        if (!userIsViewer) {
          this.setConfigurationToOwn();
        } else {
          this.setConfigurationToAll();
        }
      });
    this.subscriptions.add(userCtrRoleSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getScenarioTooltip(scenario: IScenarioGeneralInfo): string {
    const tooltipParts = [
      scenario.scenarioStatus,
      scenario.baseCase && 'Base case',
      !!scenario.submitted && 'Submitted',
    ].filter((tooltipPart: string) => tooltipPart);
    return tooltipParts.join(' - ');
  }

  public getCtrStatusClass(ctrStatus: CtrStatus) {
    switch (ctrStatus) {
      case CtrStatus.COMPLETED:
        return 'completed-status';
      case CtrStatus.IN_PROGRESS:
        return 'inprogress-status';
      case CtrStatus.CANCELLED:
        return 'cancelled-status';
      case CtrStatus.NOT_STARTED:
        return 'notstarted-status';
    }
    return 'selected-status';
  }

  public setConfigurationToOwn() {
    this.sideNavConfiguration$ = this.store.select(
      selectSidenavProjectConfigurationOwn
    );
    this.showOwnSCTRS = true;
  }

  public setConfigurationToAll() {
    this.sideNavConfiguration$ = this.store.select(
      selectSidenavProjectConfiguration
    );
    this.showOwnSCTRS = false;
  }
}

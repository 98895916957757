import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { getScenarioAction } from '@app/collections/scenarios/store/scenarios.actions';
import { selectSidenavProjectConfiguration } from '@app/layout/sidenav/store/sidenav.selectors';
import { selectProjectByIdFactory$ } from '@collections/projects/store/projects.selectors';
import { selectScenarioByScenarioIdFactory } from '@collections/scenarios/store/scenarios.selectors';
import { selectCurrentRouteUserRole } from '@core/store/core.selectors';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {
  constructor(private readonly store: Store) {}

  @Input() projectId: number;
  @Input() scenarioId: number;
  @Input() ctrId: number;

  public projectName$: Observable<string>;

  public scenarioName$: Observable<string>;

  public ctrName$: Observable<string>;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.projectId) {
      if (!this.projectId) {
        this.projectName$ = null;
      } else {
        this.projectId = parseInt(changes.projectId.currentValue, 10);
        this.projectName$ = this.store.select(selectCurrentRouteUserRole).pipe(
          filter((v) => !!v),
          take(1),
          switchMap((userRole) =>
            selectProjectByIdFactory$(this.store, this.projectId, userRole)
          ),
          map(({ projectName = null }) => projectName)
        );
      }
    }

    if (changes.scenarioId) {
      if (!this.scenarioId) {
        this.scenarioName$ = null;
      } else {
        this.scenarioId = parseInt(changes.scenarioId.currentValue, 10);
        this.scenarioName$ = this.store
          .select(selectScenarioByScenarioIdFactory(this.scenarioId))
          .pipe(
            filter((v) => !!v),
            take(1),
            map(({ scenarioName = null }) => scenarioName)
          );
        this.store.dispatch(
          getScenarioAction({ payload: { scenarioId: this.scenarioId } })
        );
      }
    }

    if (changes.ctrId) {
      if (!this.scenarioId) {
        this.ctrName$ = null;
      } else {
        this.ctrId = parseInt(changes.ctrId.currentValue, 10);
        this.ctrName$ = this.store
          .select(selectSidenavProjectConfiguration)
          .pipe(
            filter((v) => !!v),
            take(1),
            map(
              (projectMetaData) =>
                projectMetaData?.scenarios
                  ?.find((scenario) => scenario.scenarioId === this.scenarioId)
                  ?.sctrs.find((ctr) => ctr.scenarioCTRId === this.ctrId)
                  ?.ctrShortName
            )
          );
      }
    }
  }
}

<div mat-dialog-title data-test="order-dialog-title">
  <h2>Change order</h2>

  <p>
    Drag and drop to change order.<br />
    Inactive products and disciplines stay visible only for old projects. This
    way old CTR are staying accessible.
  </p>
</div>

<mat-dialog-content>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="order-list-container">
        <table
          aria-label="Products order list"
          cdkDropList
          cdkDropListOrientation="vertical"
          (cdkDropListDropped)="reorder(products, $event)"
        >
          <tr>
            <th>Products</th>
          </tr>
          <tr
            class="order-list-item"
            [class.inactive]="item.isActive === false"
            cdkDrag
            cdkDragLockAxis="y"
            *ngFor="let item of products; trackBy: trackById"
          >
            <td class="label">{{ item.label }}</td>
            <td class="inactive">{{ item.isActive ? '' : '(inactive)' }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="col-xs-12 col-md-6">
      <div class="order-list-container">
        <table
          aria-label="Disciplines order list"
          cdkDropList
          aria-label="pxd order list"
          cdkDropListOrientation="vertical"
          (cdkDropListDropped)="reorder(disciplines, $event)"
        >
          <tr>
            <th>Disciplines</th>
          </tr>
          <tr
            class="order-list-item"
            [class.inactive]="item.isActive === false"
            cdkDrag
            cdkDragLockAxis="y"
            *ngFor="let item of disciplines; trackBy: trackById"
          >
            <td class="label">{{ item.label }}</td>
            <td class="inactive">{{ item.isActive ? '' : '(inactive)' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div class="button-right" mat-dialog-actions [align]="'end'">
  <button
    [mat-dialog-close]="false"
    color="primary"
    data-test="pxd-order-dialog-dialog-close-button"
    mat-button
  >
    Cancel
  </button>

  <button
    [disabled]="!canSave"
    [mat-dialog-close]="{ disciplines, products }"
    color="primary"
    data-test="pxd-order-dialog-save-button"
    mat-button
  >
    {{ actionLabel }}
  </button>
</div>

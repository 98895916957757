import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

import { CurrenciesApiService } from '../currencies-api.service';

import {
  getCurrenciesAction,
  getCurrenciesFailureAction,
  getCurrenciesSuccessAction,
} from './currencies.actions';

@Injectable()
export class CurrenciesEffects {
  public getCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrenciesAction),
      switchMap((action) =>
        this.currenciesApiService.getCurrencies().pipe(
          map((payload) =>
            getCurrenciesSuccessAction({
              trigger: action,
              context: 'CurrenciesEffects::getCurrencies$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getCurrenciesFailureAction({
                trigger: action,
                context: 'CurrenciesEffects::getCurrencies$',
              })
            )
          )
        )
      )
    )
  );

  public initCurrenciesData$ = createEffect(() =>
    this.store.select(selectCurrentInitializedUser).pipe(
      filter((user) => !!user),
      take(1),
      switchMap(() => [
        getCurrenciesAction({
          context: 'FiltersEffects::initCurrenciesData$',
        }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly currenciesApiService: CurrenciesApiService,
    private readonly store: Store
  ) {}
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AdminScopeRequestData } from '@models/admin-scope';

import { getScopesAutocompletionDataAction } from '../store/admin-activities.actions';

export interface EditScopeDialogData {
  title: string;
  actionLabel: string;
  scopeData: Partial<AdminScopeRequestData>;
  copyFrom: number;
}

@Component({
  selector: 'app-edit-scope-dialog',
  templateUrl: './edit-scope-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditScopeDialogComponent {
  public title = this.data.title;

  public actionLabel = this.data.actionLabel;

  public scopeData: Partial<AdminScopeRequestData> = {
    ...this.data.scopeData,
  };

  public canSave = false;

  public copyMode = !!this.data.copyFrom;

  public canChangePath = !this.data.scopeData.isUsed || this.copyMode;

  public originalBlockId = this.data.scopeData.blockId;

  public originaScopeCode = this.data.scopeData.code;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditScopeDialogData,
    private readonly store: Store
  ) {
    this.store.dispatch(
      getScopesAutocompletionDataAction({
        context: 'EditScopeDialogComponent::constructor',
      })
    );
  }

  public updateState(valid: boolean) {
    const changed = Object.entries(this.scopeData).some(
      ([key, value]) => this.data.scopeData[key] !== value
    );

    this.canSave = changed && valid;
    this.canSave = (changed || this.copyMode) && valid;
  }

  public markCopyChildren(value: boolean) {
    this.scopeData = {
      ...this.scopeData,
      copyFrom: value ? this.data.copyFrom : null,
    };
  }
}

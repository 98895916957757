import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { APP_DATE_FORMATS, AppDateAdapter } from '@app/DateAdapter';
import { SdkCallService } from '@app/sdk-call.service';
import { formToServerDateConversion, saveImportedDataAs } from '@common/utils';

const kpiExportDatesValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
  const fromDate = formGroup.get('fromDate').value;
  const toDate = formGroup.get('toDate').value;
  if (!fromDate || !toDate) {
    return null;
  }
  return toDate > fromDate ? null : { dateError: true };
};

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class KpiComponent implements OnInit {
  registerForm: UntypedFormGroup;
  maxDateVal = new Date();
  showError = false;
  segmentsSelection = { surf: true, rems: false };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly sdkService: SdkCallService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        fromDate: ['', Validators.required],
        toDate: ['', [Validators.required]],
      },
      {
        validators: kpiExportDatesValidator,
      }
    );
  }

  onDownload() {
    this.registerForm.markAllAsTouched();
    if (!this.registerForm.valid) {
      return;
    }
    const businessSegmentId = this.getBusinessSegmentId();
    this.sdkService
      .downloadKpi(
        formToServerDateConversion(this.registerForm.get('fromDate')?.value),
        formToServerDateConversion(this.registerForm.get('toDate')?.value),
        businessSegmentId
      )
      .subscribe((data) => {
        if (!saveImportedDataAs(data)) {
          this.showError = true;
          this.sdkService.isLoaderVisible.next(false);
        }
      });
  }

  getBusinessSegmentId(): number {
    switch (true) {
      case this.segmentsSelection.surf && this.segmentsSelection.rems:
        return 0;
      case this.segmentsSelection.surf:
        return 1;
      case this.segmentsSelection.rems:
        return 4;
    }
  }

  checkIfKpiReportDownloadDisabled(): boolean {
    return (
      (!this.segmentsSelection.rems && !this.segmentsSelection.surf) ||
      !this.registerForm.get('fromDate').value ||
      !this.registerForm.get('toDate').value ||
      this.registerForm.invalid
    );
  }
}

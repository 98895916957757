<ng-container *ngIf="!disabled">
  <mat-select
    (click)="select.open()"
    (keydown.enter)="select.open()"
    (valueChange)="onSelection($event)"
    [attr.data-test]="controlId"
    [class.disabled]="disabled"
    [id]="controlId"
    [placeholder]="placeholder"
    [value]="value"
    #select
  >
    <mat-option [value]="option[valueName]" *ngFor="let option of options">{{
      option[labelName]
    }}</mat-option>
  </mat-select>

  <div class="subtitle" *ngIf="withSubtitle">
    {{
      options[selectedOptionIndex] && options[selectedOptionIndex][subtitleName]
    }}
  </div>
</ng-container>

<div
  *ngIf="disabled"
  [attr.data-test]="controlId + '-label'"
  class="disabled-label"
>
  {{ options[selectedOptionIndex] && options[selectedOptionIndex][labelName] }}
  <div class="subtitle" *ngIf="withSubtitle">
    {{
      options[selectedOptionIndex] && options[selectedOptionIndex][subtitleName]
    }}
  </div>
</div>

import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  // tslint:disable:component-selector
  selector: '[appCharacterCounter]',
  template: '',
  styleUrls: ['./character-counter.component.scss'],
  // tslint:disable:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class CharacterCounterComponent implements OnInit {
  private maxLength: number;
  private counterDisplayElement: HTMLElement;

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly control: NgControl,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public ngOnInit(): void {
    this.maxLength = parseInt(
      this.elementRef.nativeElement.getAttribute('maxlength'),
      10
    );

    this.counterDisplayElement = this.document.createElement('div');
    this.handleCounterContent(this.control.value);

    this.renderer.appendChild(
      this.elementRef.nativeElement.parentNode,
      this.counterDisplayElement
    );

    this.control.valueChanges.subscribe((value) => {
      this.handleCounterContent(value);
    });
  }

  private handleCounterContent(value: string) {
    this.counterDisplayElement.innerText = `${value?.length || 0} / ${
      this.maxLength
    }`;
    this.counterDisplayElement.setAttribute(
      'class',
      [
        'character-counter',
        value?.length === this.maxLength ? 'character-counter-limit' : null,
      ].join(' ')
    );
  }
}

import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { RouteDataService } from '../route-data.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  /** @deprecated replace with ngrx store routing data */
  public routeDataPageTitle$ =
    this.routerDataService.accumulatedRouteData$.pipe(
      map(({ pageTitle }) => pageTitle)
    );

  private readonly pageNameSubject$ = new BehaviorSubject('eCTR');

  /** @deprecated replace with ngrx store routing data */
  public pageName$ = merge(this.pageNameSubject$, this.routeDataPageTitle$);

  public sidenavToggle$ = new BehaviorSubject(true);

  public contentWidthChanged$ = this.sidenavToggle$.pipe(debounceTime(500));

  private readonly locks$ = new BehaviorSubject<string[]>([]);

  public isInterfaceLocked$ = this.locks$.pipe(
    map((locks) => locks.length > 0)
  );

  private readonly headerPortalSubject$ = new BehaviorSubject<Portal<any>>(
    null
  );

  public headerPortal$ = this.headerPortalSubject$.asObservable();

  constructor(private routerDataService: RouteDataService) {}

  public setPageName(name: string) {
    this.pageNameSubject$.next(name);
  }

  public lockInterface(lockKey: string) {
    if (!this.locks$.value.includes(lockKey)) {
      this.locks$.next([...this.locks$.value, lockKey]);
    }
  }

  public unlockInterface(lockKey: string) {
    this.locks$.next(this.locks$.value.filter((key) => key !== lockKey));
  }

  public setHeaderPortal(portal: Portal<any>) {
    if (this.headerPortalSubject$.value) {
      this.headerPortalSubject$.value.detach();
    }

    this.headerPortalSubject$.next(portal);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

const extractPath = ([
  { itemName: ctrName = null } = {},
  {
    itemId: blockId = null,
    itemCode: blockCode = null,
    itemName: blockName = null,
  } = {},
  {
    itemId: scopeId = null,
    itemCode: scopeCode = null,
    itemName: scopeName = null,
  } = {},
  {
    itemId: activityId = null,
    itemCode: activityCode = null,
    itemName: activityName = null,
  } = {},
  ...rest
]) => ({
  ctrName,
  blockName,
  blockCode,
  blockId,
  scopeName,
  scopeCode,
  scopeId,
  activityName,
  activityCode,
  activityId,
});

const treeReducer = (
  path: any[],
  { itemId, itemCode, itemName, changedProperties, items, status }
) => {
  const leafPath = extractPath([...path, { itemId, itemCode, itemName }]);

  return [
    status === 4 ? { ...leafPath, action: 'Reordered' } : null,
    status === 3 ? { ...leafPath, action: 'Deleted' } : null,
    status === 2 ? { ...leafPath, action: 'Created' } : null,
    ...(changedProperties || []).map((change) => ({
      ...leafPath,
      field: change.key,
      action: ['No Changes', 'Updated', 'Created', 'Deleted', 'Reordered'][
        status
      ],
      valueBefore: change.oldValue,
      valueAfter: change.newValue,
    })),
    ...(items || []).reduce(
      (result, item) => [
        ...result,
        ...(item
          ? treeReducer([...path, { itemId, itemCode, itemName }], item)
          : []),
      ],
      []
    ),
  ].filter((v) => !!v);
};

@Injectable({ providedIn: 'root' })
export class ActivitiesChangeLogApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getLatestChanges() {
    return this.httpClient.get<any>(`{apiUrl}masterdata/changelog`).pipe(
      map(({ userName, userEmail, createdOn, items }) => ({
        userName,
        userEmail,
        createdOn: new Date(createdOn + 'Z'), // server date are missing zone indicator
        changes: items.reduce(
          (r, item) => [...r, ...treeReducer([], item)],
          []
        ),
      })),
      map((changelogData) => {
        // server returns lock expiration time which is +5min (lock expiration time) in future
        // so last change was 5min earlier
        changelogData.createdOn.setTime(
          changelogData.createdOn.getTime() - 5 * 60000
        );
        return changelogData;
      })
    );
  }
}

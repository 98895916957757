import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import {
  selectCurrentUser,
  selectCurrentUserIsAdmin,
  selectMainAccountIsAdmin,
  selectShortRoleName,
} from '@collections/users/store/users.selectors';
import { LayoutService } from '@core/layout/layout.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public actualAdmin$ = this.store.select(selectMainAccountIsAdmin);

  public isAdmin$ = this.store.select(selectCurrentUserIsAdmin);

  public currentUser$ = this.store.select(selectCurrentUser);

  public userSignature$ = this.currentUser$.pipe(
    map((user) => user.firstName[0] + user.lastName[0])
  );

  public shortRoleName$ = this.store.select(selectShortRoleName);

  public displaySurveyBanner = Date.now() <= new Date('2023-04-17').getTime();

  constructor(
    private readonly authService: MsalService,
    public readonly layoutService: LayoutService,
    private readonly store: Store
  ) {}

  public logout() {
    if (environment.authentication) {
      this.authService.logout();
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { getClientsAction } from '@collections/clients/store/clients.actions';
import { getEntitiesAction } from '@collections/entities/store/entities.actions';
import { getProjectStagesAction } from '@collections/project-stages/store/project-stages.actions';
import {
  patchProjectsListFiltersAction,
  resetProjectsListFiltersAction,
} from '@collections/projects/store/projects.actions';
import {
  selectProjectsListFilterNameFactory$,
  selectProjectsListFiltersFactory$,
} from '@collections/projects/store/projects.selectors';
import {
  selectCurrentInitializedUser,
  selectCurrentUserId,
} from '@collections/users/store/users.selectors';
import { ConditionType, SortType } from '@common/enums';
import { UserRole } from '@core/store/core.reducer';
import { IFilter } from '@models/filters';

import { FiltersApiService } from '../filters-api.service';

import { filtersFormFieldConfiguration } from './filters-form.config';
import {
  applyCurrentConditionsFromProjectsAction,
  applyFilterAction,
  applyNewFilterAndCreateAction,
  createFilterAction,
  deleteFilterAction,
  deleteFilterFailureAction,
  deleteFilterSuccessAction,
  getCurrentConditionsFromProjectsAction,
  getFiltersAction,
  getFiltersFailureAction,
  getFiltersSuccessAction,
  removeFilterConditionAction,
  setFilterAsCurrentAction,
  setMyDefaultFilterAsCurrentFilterAction,
  upsertFilterAction,
  upsertFilterFailureAction,
  upsertFilterSuccessAction,
  upsertManyFiltersAction,
} from './filters.actions';
import {
  MY_CTRS,
  MY_PROJECTS_NAME,
  selectCurrentFilterFactory$,
  selectFilterAsSearchParamsFactory$,
  selectMyDefaultFilterFactory$,
} from './filters.selectors';

@Injectable()
export class FiltersEffects {
  public getFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFiltersAction),
      switchMap((action) =>
        this.filtersApiService.getFilters().pipe(
          map((filters) =>
            getFiltersSuccessAction({
              trigger: action,
              context: 'FiltersEffects::getFilters$',
              payload: { filters, context: action.payload.context },
            })
          ),
          catchError(() =>
            of(
              getFiltersFailureAction({
                trigger: action,
                context: 'FiltersEffects::getFilters$',
                payload: { context: action.payload.context },
              })
            )
          )
        )
      )
    )
  );

  public createFilterIfNameProvided$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyNewFilterAndCreateAction),
      map((action) =>
        createFilterAction({
          trigger: action,
          context: 'FiltersEffects::createFilterIfNameProvided$',
          payload: {
            context: action.payload.context,
          },
        })
      )
    )
  );

  public createFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFilterAction),
      switchMap((action) =>
        selectCurrentFilterFactory$(this.store, action.payload.context).pipe(
          filter((v) => !!v),
          take(1),
          map((latest) =>
            upsertFilterAction({
              trigger: action,
              context: 'FiltersEffects::createFilter$',
              payload: { filter: latest, context: action.payload.context },
            })
          )
        )
      )
    )
  );

  public upsertFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertFilterAction),
      switchMap((action) =>
        this.filtersApiService.postFilter(action.payload.filter).pipe(
          map(() =>
            upsertFilterSuccessAction({
              trigger: action,
              context: 'FiltersEffects::upsertFilter$',
              payload: action.payload,
            })
          ),
          catchError(() =>
            of(
              upsertFilterFailureAction({
                trigger: action,
                context: 'FiltersEffects::upsertFilter$',
                payload: action.payload,
              })
            )
          )
        )
      )
    )
  );

  public deleteFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFilterAction),
      switchMap((action) =>
        this.filtersApiService.deleteFilter(action.payload.filterId).pipe(
          map(() =>
            deleteFilterSuccessAction({
              trigger: action,
              context: 'FiltersEffects::deleteFilter$',
              payload: action.payload,
            })
          ),
          catchError(() =>
            of(
              deleteFilterFailureAction({
                trigger: action,
                context: 'FiltersEffects::deleteFilter$',
                payload: action.payload,
              })
            )
          )
        )
      )
    )
  );

  public applyFilterAfterConditionRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFilterConditionAction),
      withLatestFrom(this.store.select(selectCurrentUserId)),
      map(([action, userId]) => {
        return applyFilterAction({
          trigger: action,
          context: 'FiltersEffects::applyFilterAfterConditionRemove$',
          payload: { userId, context: action.payload.context },
        });
      })
    )
  );

  public applyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        applyFilterAction,
        setFilterAsCurrentAction,
        applyNewFilterAndCreateAction
      ),
      switchMap((action) =>
        selectFilterAsSearchParamsFactory$(
          this.store,
          action.payload.context
        ).pipe(
          filter((v) => !!v),
          take(1),
          map((searchParams) =>
            patchProjectsListFiltersAction({
              trigger: action,
              context: 'FiltersEffects::applyFilter$',
              payload: {
                params: searchParams,
                context: action.payload.context,
              },
            })
          )
        )
      )
    )
  );

  public initAutoCompleteData$ = createEffect(() =>
    this.store.select(selectCurrentInitializedUser).pipe(
      filter((user) => !!user),
      take(1),
      switchMap(() => [
        getProjectStagesAction({
          context: 'FiltersEffects::initAutocompletionData$',
        }),
        getEntitiesAction({
          context: 'FiltersEffects::initAutocompletionData$',
        }),
        getClientsAction({
          context: 'FiltersEffects::initAutocompletionData$',
        }),
      ])
    )
  );

  public initStandardFilters$ = createEffect(() =>
    this.store.select(selectCurrentInitializedUser).pipe(
      filter((user) => !!user),
      distinctUntilChanged((a, b) => a.userId === b.userId),
      switchMap((user) => [
        ...(user.isRequestor
          ? [
              upsertManyFiltersAction({
                context: 'FiltersEffects::initStandardFilters$:requestor',
                payload: {
                  filters: [
                    {
                      name: MY_PROJECTS_NAME,
                      userId: null,
                      isFavourite: false,
                      isPOFilter: false,
                      sortType: SortType.CreatedNewest,
                      conditions: [
                        {
                          type: ConditionType.Requestor,
                          value: user.userId.toString(10),
                        },
                      ],
                    } as IFilter,
                    {
                      name: 'My entity projects',
                      userId: null,
                      isFavourite: false,
                      isPOFilter: false,
                      sortType: SortType.CreatedNewest,
                      conditions: [
                        {
                          type: ConditionType.Entity,
                          value: user.entityId.toString(10),
                        },
                      ],
                    } as IFilter,
                    {
                      name: 'Created last 10 days',
                      userId: null,
                      isFavourite: false,
                      isPOFilter: false,
                      sortType: SortType.CreatedNewest,
                      conditions: [
                        {
                          type: ConditionType.CreatedAfter,
                          value: new Date(
                            Date.now() - 24 * 60 * 60 * 1000
                          ).toISOString(),
                        },
                      ],
                    } as IFilter,
                  ],
                  context: UserRole.REQUESTOR,
                },
              }),
              setMyDefaultFilterAsCurrentFilterAction({
                context: 'FiltersEffects::initStandardFilters$:requestor',
                payload: {
                  context: UserRole.REQUESTOR,
                },
              }),
            ]
          : []),
        ...(user.isEngineer
          ? [
              upsertManyFiltersAction({
                context: 'FiltersEffects::initStandardFilters$:engineer',
                payload: {
                  filters: [
                    {
                      name: MY_CTRS,
                      userId: null,
                      isFavourite: false,
                      isPOFilter: true,
                      sortType: SortType.CTRRequestedNewest,
                      conditions: [
                        {
                          type: ConditionType.UserIsOwner,
                          value: 'true',
                        },
                        {
                          type: ConditionType.UserIsSupporter,
                          value: 'true',
                        },
                      ],
                    } as IFilter,
                    {
                      name: 'My entity CTRs',
                      userId: null,
                      isFavourite: false,
                      isPOFilter: true,
                      sortType: SortType.CreatedNewest,
                      conditions: [
                        {
                          type: ConditionType.Entity,
                          value: user.entityId.toString(10),
                        },
                      ],
                    } as IFilter,
                  ],
                  context: UserRole.ENGINEER,
                },
              }),
              setMyDefaultFilterAsCurrentFilterAction({
                context: 'FiltersEffects::initStandardFilters$:engineer',
                payload: {
                  context: UserRole.ENGINEER,
                },
              }),
            ]
          : []),
      ])
    )
  );

  public setMyDefaultFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMyDefaultFilterAsCurrentFilterAction),
      groupBy((action) => action.payload.context),
      mergeMap((group$) =>
        group$.pipe(
          switchMap((action) =>
            selectMyDefaultFilterFactory$(
              this.store,
              action.payload.context
            ).pipe(
              take(1),
              map((myProjectsFilter) =>
                myProjectsFilter
                  ? setFilterAsCurrentAction({
                      context: 'FiltersEffects::setMyProjects$',
                      trigger: action,
                      payload: {
                        filter: myProjectsFilter,
                        context: action.payload.context,
                      },
                    })
                  : resetProjectsListFiltersAction({
                      context: 'FiltersEffects::setMyProjects$',
                      trigger: action,
                      payload: {
                        context: action.payload.context,
                      },
                    })
              )
            )
          )
        )
      )
    )
  );

  public pullFromProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentConditionsFromProjectsAction),
      withLatestFrom(
        selectProjectsListFiltersFactory$(this.store),
        selectProjectsListFilterNameFactory$(this.store)
      ),
      map(([action, filterParams, filterName]) =>
        applyCurrentConditionsFromProjectsAction({
          context: 'FilterEffects::pullFromProjects$',
          trigger: action,
          payload: {
            filter: {
              name: filterName,
              isFavourite: false,
              sortType: filterParams.sortType,
              conditions: Object.entries(filterParams)
                .map(([conditionTypeName, conditionValue]) => [
                  filtersFormFieldConfiguration[conditionTypeName],
                  conditionValue,
                ])
                .filter(([typeConfiguration]) => !!typeConfiguration)
                .map(([typeConfiguration, conditionValue]) => ({
                  type: typeConfiguration.type,
                  value: conditionValue,
                })),
            },
            context: action.payload.context,
          },
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly filtersApiService: FiltersApiService
  ) {}
}

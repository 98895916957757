import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { selectCurrentUserId } from '@collections/users/store/users.selectors';
import { UserRole } from '@core/store/core.reducer';
import {
  selectCurrentRouteUserRole,
  selectCurrentRouteUserRoleIsEngineer,
} from '@core/store/core.selectors';
import { convertFilterToSearchParamsObject } from './filters-form.config';
import {
  filtersEntityAdapter,
  IFiltersCollectionState,
} from './filters.reducer';
export const MY_PROJECTS_NAME = 'My projects';

export const MY_CTRS = 'My CTRs';

export class FiltersSelectors {
  constructor(private readonly feature: string) {}

  public selectFiltersCollectionState =
    createFeatureSelector<IFiltersCollectionState>(this.feature);

  public selectAllFilters = createSelector(
    filtersEntityAdapter.getSelectors(this.selectFiltersCollectionState)
      .selectAll,
    (v) => v
  );

  public selectProjectsMap = createSelector(
    filtersEntityAdapter.getSelectors(this.selectFiltersCollectionState)
      .selectEntities,
    (v) => v
  );

  public selectCurrentFilter = createSelector(
    this.selectFiltersCollectionState,
    ({ currentFilter }) => currentFilter
  );

  public selectGetFiltersSuccess = createSelector(
    this.selectFiltersCollectionState,
    ({ getFiltersSuccess }) => getFiltersSuccess
  );

  public selectStandardFilters = createSelector(
    this.selectAllFilters,
    selectCurrentRouteUserRoleIsEngineer,
    (filters, userIsEngineer) => {
      return filters.filter(
        (f) => f.userId === null && f.isPOFilter === userIsEngineer
      );
    }
  );

  public selectSavedFilters = createSelector(
    this.selectAllFilters,
    selectCurrentUserId,
    (filters, userId) => filters.filter((f) => f.userId === userId)
  );

  public selectFavoriteFilters = createSelector(
    this.selectSavedFilters,
    (filters) => {
      return filters.filter((f) => f.isFavourite);
    }
  );

  public selectFilterAsSearchParams = createSelector(
    this.selectCurrentFilter,
    (currentFilter) => convertFilterToSearchParamsObject(currentFilter)
  );

  public selectRequestorDefaultFilter = createSelector(
    this.selectAllFilters,
    selectCurrentUserId,
    (filters, userId) =>
      filters.find(
        (f) =>
          f.name === MY_PROJECTS_NAME &&
          f.conditions.find(
            (condition) => parseInt(condition.value, 10) === userId
          )
      )
  );

  public selectEngineerDefaultFilter = createSelector(
    this.selectAllFilters,
    (filters) => filters.find((f) => f.name === MY_CTRS)
  );
}

export const filtersSelectors = {
  [UserRole.REQUESTOR]: new FiltersSelectors('requestor-filters'),
  [UserRole.ENGINEER]: new FiltersSelectors('engineer-filters'),
};

const getFiltersSelectorsByContext = (userRole) =>
  [UserRole.REQUESTOR, UserRole.ENGINEER].includes(userRole)
    ? (filtersSelectors[userRole] as FiltersSelectors)
    : null;

export const selectGetFiltersSuccessFactoryByUserContext$ = (
  store: Store,
  userRoleContext: UserRole
) => {
  const selector = getFiltersSelectorsByContext(userRoleContext);

  return selector ? store.select(selector.selectGetFiltersSuccess) : EMPTY;
};

export const selectStandardFiltersFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRoleContext) => {
      const selector = getFiltersSelectorsByContext(userRoleContext);

      return selector ? store.select(selector.selectStandardFilters) : EMPTY;
    })
  );

export const selectFavoriteFiltersFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRoleContext) => {
      const selector = getFiltersSelectorsByContext(userRoleContext);

      return selector ? store.select(selector.selectFavoriteFilters) : EMPTY;
    })
  );

export const selectSavedFiltersFactory$ = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRoleContext) => {
      const selector = getFiltersSelectorsByContext(userRoleContext);

      return selector ? store.select(selector.selectSavedFilters) : EMPTY;
    })
  );

export const selectMyDefaultFilterFactory$ = (
  store: Store,
  userRoleContext: UserRole
) => {
  const selector = getFiltersSelectorsByContext(userRoleContext);

  return selector
    ? userRoleContext === UserRole.REQUESTOR
      ? store.select(selector.selectRequestorDefaultFilter)
      : userRoleContext === UserRole.ENGINEER
      ? store.select(selector.selectEngineerDefaultFilter)
      : EMPTY
    : EMPTY;
};

export const selectCurrentFilterFactory$ = (
  store: Store,
  userRoleContext: UserRole
) => {
  const selector = getFiltersSelectorsByContext(userRoleContext);

  return selector ? store.select(selector.selectCurrentFilter) : EMPTY;
};

export const selectFilterAsSearchParamsFactory$ = (
  store: Store,
  userRoleContext: UserRole
) => {
  const selector = getFiltersSelectorsByContext(userRoleContext);

  return selector ? store.select(selector.selectFilterAsSearchParams) : EMPTY;
};

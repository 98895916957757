<h2 mat-dialog-title>{{ dialogConfig.content.title }}</h2>
<mat-dialog-content>
  <div class="content">
    <ng-container *ngIf="dialogConfig.content.ctrName">
      <div class="name-container">
        <svg
          class="ctr-status-image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 17"
        >
          <path
            d="M.1 15.8C-1 13.9 7.7 0 10.1 0s10.7 14.3 9.8 15.8-18.6 1.9-19.8 0z"
            class="{{ getStatusColorClass() }}"
          />
        </svg>
        <span class="name">{{ dialogConfig.content.ctrName }}</span>
      </div>
      <div>
        Revision {{ dialogConfig.content.revisionNumber }} -
        {{ dialogConfig.content.status }}
      </div>
    </ng-container>
    <ng-container *ngIf="dialogConfig.content.scenarioName">
      <div class="name-container">
        <app-scenario-status-icon
          [scenarioNumber]="dialogConfig.content.scenarioNumber"
          [scenarioId]="dialogConfig.content.scenarioId"
          [scenarioStatus]="dialogConfig.content.status"
        ></app-scenario-status-icon>
        <span class="name" data-test="scenario-export-scenario-name">{{
          dialogConfig.content.scenarioName
        }}</span>
      </div>
      <div data-test="scenario-export-scenario-status-description">
        {{ getCtrsQuantityDescription() }}
      </div>
      @if(dialogConfig.downloadHandlers.downloadiQuestExcelHandler){
      <div class="iquest-note">
        NB: For iQuest export - the Start Date is the earliest date the work is
        performed and not the contract award date.
      </div>
      }
    </ng-container>
  </div>

  <div class="download-buttons-container">
    <button
      mat-stroked-button
      color="primary"
      (click)="dialogConfig.downloadHandlers.downloadExcelHandler()"
      mat-dialog-close
      data-test="ctr-export-excel-button"
    >
      Excel
    </button>
    <button
      *ngIf="dialogConfig.downloadHandlers.downloadClientExcelHandler"
      mat-stroked-button
      color="primary"
      (click)="dialogConfig.downloadHandlers.downloadClientExcelHandler()"
      mat-dialog-close
      data-test="ctr-export-client-excel-button"
    >
      Client Excel
    </button>
    <button
      *ngIf="dialogConfig.downloadHandlers.downloadiQuestExcelHandler"
      mat-stroked-button
      color="primary"
      (click)="dialogConfig.downloadHandlers.downloadiQuestExcelHandler()"
      mat-dialog-close
      data-test="ctr-export-iquest-excel-button"
    >
      iQuest Excel
    </button>
    <div *ngIf="dialogConfig.content.ctrName">
      <button
        mat-stroked-button
        color="primary"
        (click)="dialogConfig.downloadHandlers.downloadPdfHandler()"
        mat-dialog-close
        data-test="ctr-export-pdf-button"
      >
        PDF
      </button>
    </div>
    <div
      *ngIf="dialogConfig.content.scenarioName"
      [matTooltip]="'Feature under construction'"
    >
      <button
        mat-stroked-button
        color="primary"
        [disabled]="true"
        (click)="dialogConfig.downloadHandlers.downloadPdfHandler()"
        mat-dialog-close
      >
        PDF
      </button>
    </div>
    <button
      mat-stroked-button
      color="primary"
      (click)="dialogConfig.downloadHandlers.downloadMdrHandler()"
      mat-dialog-close
      data-test="ctr-export-mdr-button"
    >
      MDR
    </button>
    <button
      mat-stroked-button
      color="primary"
      *ngIf="dialogConfig.downloadHandlers.downloadPdfClientHandler"
      (click)="dialogConfig.downloadHandlers.downloadPdfClientHandler()"
      mat-dialog-close
      data-test="ctr-export-pdf-client-button"
    >
      PDF Client
    </button>
  </div>
</mat-dialog-content>
<div mat-dialog-actions [align]="'end'">
  <button
    color="primary"
    mat-button
    mat-dialog-close
    data-test="cancel-ctr-export-button"
    tabindex="0"
  >
    Cancel
  </button>
</div>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

import { getProjectSuccessAction } from '@collections/projects/store/projects.actions';
import { RouteDataService } from '@core/route-data.service';

import { SidenavApiService } from '../sidenav-api.service';

import {
  emptyNavbarAction,
  getSidenavProjectDetailsAction,
  getSidenavProjectDetailsActionFailure,
  getSidenavProjectDetailsActionSuccess,
  prepareNewProjectNavbarAction,
} from './sidenav.actions';

@Injectable({ providedIn: 'root' })
export class SidenavEffects {
  public getSidenavDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSidenavProjectDetailsAction),
      debounceTime(1000),
      switchMap((action) =>
        this.sidenavApiService
          .getNavBarDetails(action.payload.projectId, false)
          .pipe(
            map((payload) => {
              return getSidenavProjectDetailsActionSuccess({
                trigger: action,
                context: 'SidenavEffects::getSidenavDetails$',
                payload,
              });
            }),
            catchError(() =>
              of(
                getSidenavProjectDetailsActionFailure({
                  trigger: action,
                  context: 'SidenavEffects::getSidenavDetails$',
                })
              )
            )
          )
      )
    )
  );

  public activeRouteParamsChange$ = createEffect(() =>
    this.routeDataService.activatedRoute$.pipe(
      map(
        ({
          params: { projectId = null, scenarioId = null },
          data: { projectView = false },
        }) => ({
          projectId: parseInt(projectId, 10),
          scenarioId: parseInt(scenarioId, 10),
          projectView,
        })
      ),
      distinctUntilChanged(
        (a, b) => a.projectId === b.projectId && !!a.projectId && !!b.projectId
      ),
      map(({ projectId, projectView }) => {
        if (projectView) {
          if (projectId) {
            return getSidenavProjectDetailsAction({
              context: 'SidenavEffects::activeRouteParamsChange$',
              payload: { projectId },
            });
          }

          return prepareNewProjectNavbarAction({
            context: 'SidenavEffects::activeRouteParamsChange$',
          });
        }

        return emptyNavbarAction({
          context: 'SidenavEffects::activeRouteParamsChange$',
        });
      })
    )
  );

  public projectsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectSuccessAction),
      map((action) =>
        getSidenavProjectDetailsAction({
          trigger: action,
          context: 'SidenavEffects::projectsChanged$',
          payload: { projectId: action.payload.project.id },
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sidenavApiService: SidenavApiService,
    private readonly routeDataService: RouteDataService
  ) {}
}

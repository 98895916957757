import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetBusinessSegmentsResponseItem } from '@models/business-segments';

@Injectable({ providedIn: 'root' })
export class BusinessSegmentsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getBusinessSegments() {
    return this.httpClient.get<IGetBusinessSegmentsResponseItem[]>(
      '{apiUrl}admin/businessSegments'
    );
  }
}

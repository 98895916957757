import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mapTo } from 'rxjs/operators';

import { Discipline } from '@models/discipline';
import { OrderableItem } from '@models/orderable-item';
import { Product } from '@models/product';
import { IPxD, IPxDDetailed, ProductsAndDisciplines } from '@models/pxd';

@Injectable({ providedIn: 'root' })
export class PxDsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getMinifiedPxDs(businessSegmentId: number) {
    return this.httpClient.get<IPxD[]>(
      `{apiUrl}pxd/defined/minified/${businessSegmentId}`
    );
  }

  public getPxDsPrincipals(businessSegmentId: number) {
    return this.httpClient
      .get<IPxD[]>(`{apiUrl}pxd/defined/${businessSegmentId}`)
      .pipe(
        map((pxds) =>
          pxds.map((pxd) => ({
            id: pxd.id,
            principals: pxd.principals,
          }))
        )
      );
  }

  public getProductsAndDisciplines(businessSegmentId) {
    return this.httpClient
      .get<ProductsAndDisciplines>(
        `{apiUrl}pxd/productsAndDisciplines/${businessSegmentId}`
      )
      .pipe(
        map(({ products, disciplines }) => ({
          products: products.map((product) => ({
            businessSegmentId,
            ...product,
          })),
          disciplines: disciplines.map((discipline) => ({
            businessSegmentId,
            ...discipline,
          })),
        }))
      );
  }

  public patchPxDsOrder(
    businessSegmentId: number,
    products: OrderableItem[],
    disciplines: OrderableItem[]
  ) {
    return this.httpClient
      .patch(`{apiUrl}pxd/order`, {
        businessSegmentId,
        products: products.map((product) => product.id),
        disciplines: disciplines.map((discipline) => discipline.id),
      })
      .pipe(mapTo({ businessSegmentId, products, disciplines }));
  }

  public createProduct(product: Product) {
    return this.httpClient.post<number>(
      `{apiUrl}admin/products/${product.businessSegmentId}`,
      product
    );
  }

  public createDiscipline(discipline: Discipline) {
    return this.httpClient.post<number>(
      `{apiUrl}admin/disciplines/${discipline.businessSegmentId}`,
      discipline
    );
  }

  public editProduct(product: Product) {
    return this.httpClient.put<number>(
      `{apiUrl}admin/products/${product.id}`,
      product
    );
  }

  public editDiscipline(discipline: Discipline) {
    return this.httpClient.put<number>(
      `{apiUrl}admin/disciplines/${discipline.id}`,
      discipline
    );
  }

  public deleteProduct(productId: number) {
    return this.httpClient.delete(`{apiUrl}admin/products/${productId}`);
  }

  public deleteDiscipline(disciplineId: number) {
    return this.httpClient.delete(`{apiUrl}admin/disciplines/${disciplineId}`);
  }

  public getPxDDetails(pxdId: number) {
    return this.httpClient.get<IPxDDetailed>(`{apiUrl}pxd/ctr/${pxdId}`);
  }

  public patchPxD(pxd: IPxD) {
    return this.httpClient.patch<IPxDDetailed>(
      `{apiUrl}pxd/ctr/${pxd.id}`,
      pxd
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CalendarizationCtrsToEditService {
  private readonly _checkedCtrsToEdit = new BehaviorSubject<any[]>([]);
  public checkedCtrsToEdit$: Observable<any[]> =
    this._checkedCtrsToEdit.asObservable();

  setCheckedCtrsToEdit(checkedCtrsToEdit: any[]) {
    this._checkedCtrsToEdit.next(checkedCtrsToEdit);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetScenarioSummaryCTRsResponseItem } from '@app/models/scenario-summary-ctrs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScenarioSummaryCTRsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getScenarioSummaryCTRs(scenarioId: number) {
    return this.httpClient
      .get<IGetScenarioSummaryCTRsResponseItem[]>(
        `{apiUrl}scenarios/${scenarioId}/summary`
      )
      .pipe(
        map((scenarios) =>
          scenarios.map((scenario) => ({ ...scenario, scenarioId }))
        )
      );
  }
}

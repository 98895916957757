<mat-form-field appearance="{{ appearance }}" class="values-chips">
  <mat-label>{{ chipsName }}</mat-label>
  <mat-chip-grid
    #chipList
    (click)="openAutocompletePanel()"
    (keydown.enter)="openAutocompletePanel()"
    [attr.aria-label]="chipsName + 'filter'"
  >
    <mat-chip-row
      (click)="$event.stopPropagation()"
      (keydown.enter)="$event.stopPropagation()"
      (removed)="remove(condition)"
      *ngFor="let condition of selectedValues$ | async"
      [removable]="removable && !disabled"
      [selectable]="selectable && !disabled"
      class="standard-chip"
    >
      {{ condition.name }}
      <mat-icon *ngIf="removable && !disabled" matChipRemove>cancel </mat-icon>
    </mat-chip-row>
    <input
      #chipsInput
      [formControl]="inputFormControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [placeholder]="placeholder"
    />
    <input
      (dateInput)="check($event.value)"
      [matDatepicker]="picker"
      type="hidden"
    />
  </mat-chip-grid>
  <mat-datepicker #picker></mat-datepicker>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event)"
    [autoActiveFirstOption]="true"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Assumptions } from '@models/ctr-assumptions';
import { Exclusions } from '@models/ctr-exclusions';

import {
  FEATURE_KEY,
  IAssumptionsAndExclusionsState,
  assumptionsAndExclusionsEntityAdapter,
} from './assumptions-and-exclusions.reducer';

export const selectAssumptionsAndExclusionsCollectionState =
  createFeatureSelector<IAssumptionsAndExclusionsState>(FEATURE_KEY);

const selectAssumptionsAndExclusionsCollection = createSelector(
  selectAssumptionsAndExclusionsCollectionState,
  (assumptionsAndExclusions) => assumptionsAndExclusions
);

export const assumptionsAndExclusionsSelectors =
  assumptionsAndExclusionsEntityAdapter.getSelectors(
    selectAssumptionsAndExclusionsCollection
  );

export const selectCTRAssumptionsAndExclusionsFactory = (ctrId: number) =>
  createSelector(
    assumptionsAndExclusionsSelectors.selectAll,
    (assumptionsAndExclusions) =>
      assumptionsAndExclusions.find((x) => ctrId === x.ctrId)
  );

export const selectCTRAssumptionsFactory = (ctrId: number) =>
  createSelector(
    selectCTRAssumptionsAndExclusionsFactory(ctrId),
    (assumptionsAndExclusions) =>
      assumptionsAndExclusions.assumptions
        .slice()
        .sort(sortAssumptionsOrExclusions)
  );

export const selectCTRExclusionsFactory = (ctrId: number) =>
  createSelector(
    selectCTRAssumptionsAndExclusionsFactory(ctrId),
    (assumptionsAndExclusions) =>
      assumptionsAndExclusions.exclusions
        .slice()
        .sort(sortAssumptionsOrExclusions)
  );

export const selectCTRGeneralAssumptionsFactory = (ctrId: number) =>
  createSelector(
    selectCTRAssumptionsAndExclusionsFactory(ctrId),
    (assumptionsAndExclusions) =>
      assumptionsAndExclusions?.assumptions.find((x) => !x.sctrScopeId)
        ?.assumptions
  );

export const selectCTRGeneralExclusionsFactory = (ctrId: number) =>
  createSelector(
    selectCTRAssumptionsAndExclusionsFactory(ctrId),
    (assumptionsAndExclusions) =>
      assumptionsAndExclusions?.exclusions.find((x) => !x.sctrScopeId)
        ?.exclusions
  );

function sortAssumptionsOrExclusions(
  a: Assumptions | Exclusions,
  b: Assumptions | Exclusions
) {
  return sortByEquipmentIdEqualNull(a, b) || sortByOrder(a, b);
}

function sortByEquipmentIdEqualNull(
  a: Assumptions | Exclusions,
  b: Assumptions | Exclusions
) {
  if (a.sctrEquipId === null) {
    return 1;
  }
  if (a.sctrEquipId === null) {
    return -1;
  }
  if (a.sctrEquipId !== null) {
    return 0;
  }
}

function sortByOrder(a: Assumptions | Exclusions, b: Assumptions | Exclusions) {
  if (a.blockOrder && b.blockOrder && a.sctrScopeId && b.sctrScopeId) {
    return (
      a.blockOrder - b.blockOrder ||
      a.scopeOrder - b.scopeOrder ||
      a.sctrEquipId - b.sctrEquipId
    );
  }
}

<div class="choosetableroot">
  <form *ngIf="definitionSection != null" [formGroup]="definitionSection">
    <table formArrayName="defApplication" class="table2 table">
      <thead>
        <tr>
          <th colspan="2" scope="empty"></th>
          <ng-container *ngFor="let item of scenario; let j = index">
            <th
              class="boldrow"
              [ngClass]="{ 'td-grayed': item?.status === cancelledStatus }"
            >
              <a
                class="project-scen-achor"
                [routerLink]="['/viewscenario', projectId, item.scenarioId]"
                [matTooltip]="getScenarioTooltip(item)"
              >
                <app-scenario-status-icon
                  [scenarioNumber]="item.number"
                  [scenarioStatus]="item.status"
                  [scenarioSubmitted]="item.submitted"
                  data-test="scenario-definition-scenario-status-icon"
                ></app-scenario-status-icon>
                <p data-test="scenario-definition-scenario-name-label">
                  {{ item?.scenarioName }}
                </p>
                <mat-icon
                  *ngIf="item?.isBaseCase"
                  title="Basecase"
                  class="basecase-icon"
                  data-test="scenario-definition-scenario-base-case-icon"
                  >star</mat-icon
                >
              </a>
            </th>
          </ng-container>
        </tr>
        <tr>
          <th
            class="boldrow"
            data-test="scenario-status-label"
            colspan="2"
            scope="status"
          >
            Scenario status
          </th>
          <ng-container *ngFor="let item of scenario; let j = index">
            <th
              [ngClass]="{ 'td-grayed': item?.status === cancelledStatus }"
              data-test="scenario-definition-scenario-status"
            >
              {{ item.status }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let appln of getApplicationData(); let scIndex = index"
          [formGroupName]="scIndex"
        >
          <tr class="tr-application">
            <td class="boldrow" colspan="2">
              {{ appln?.get('applicationName').value }}
            </td>
            <ng-container *ngFor="let item of scenario; let j = index">
              <td></td>
            </ng-container>
          </tr>
          <ng-container formArrayName="equipments">
            <tr
              *ngFor="
                let equip of appln?.get('equipments')['controls'];
                let iIndex = index
              "
              [formGroupName]="iIndex"
            >
              <td></td>
              <td>{{ equip?.get('equipmentName').value }}</td>
              <ng-container
                *ngFor="let item of scenario; let j = index"
                formArrayName="scenarioDefinitions"
              >
                <ng-container
                  *ngFor="
                    let def of equip?.get('scenarioDefinitions')['controls'];
                    let i = index
                  "
                >
                  <td
                    class="td-scenario"
                    [ngClass]="{
                      'td-grayed': item?.status === cancelledStatus
                    }"
                    *ngIf="item?.scenarioId == def?.get('scenarioId').value"
                  >
                    {{ def?.get('scenarioDefinitionValue').value }}
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>

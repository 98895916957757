import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';

import { LayoutService } from '@core/layout/layout.service';
import { LockOwnerState, LockService } from '@core/lock/lock.service';

import { AdminEditModeInfoDialogComponent } from './admin-edit-mode-info-dialog/admin-edit-mode-info-dialog.component';

@Component({
  selector: 'app-lock-indicator',
  templateUrl: './lock-indicator.component.html',
  styleUrls: ['./lock-indicator.component.scss'],
})
export class LockIndicatorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('headerPortalContent') headerPortalContent: TemplateRef<unknown>;

  public lockOwnerState$ = this.lockService.lockOwnerState$;

  public lockedBy$ = combineLatest([
    this.lockService.lock$,
    this.lockService.lockOwnerState$,
  ]).pipe(
    map(([lock, lockOwnerState]) => {
      return {
        [LockOwnerState.UNLOCKED]: { prefixLabel: 'You are in Read mode' },
        [LockOwnerState.OWNER]: { prefixLabel: 'You are in Edit mode' },
        [LockOwnerState.EXPIRED]: { prefixLabel: 'You are in Read mode' },
        [LockOwnerState.LOCKED]: {
          prefixLabel: `You are in Read mode. Edit mode disabled: `,
          suffixLabel: `is editing.`,
          userName: lock?.lockedBy,
          userEmail: lock?.email,
        },
      }[lockOwnerState];
    }),
    shareReplay(1)
  );

  public lockExpirationCountdown$ =
    this.lockService.lockExpirationCountdown$.pipe(
      withLatestFrom(this.lockService.lockOwnerState$),
      map(([time, lockOwnerState]) => {
        if (
          time > 180 ||
          time <= 0 ||
          lockOwnerState !== LockOwnerState.OWNER
        ) {
          return ``;
        }

        const min = Math.floor(time / 60);
        const sec = time % 60;

        return `Lock will expire in: ${min.toFixed(0)}:${sec
          .toFixed(0)
          .padStart(2, '0')}`;
      })
    );

  public LockOwnerState = LockOwnerState;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly lockService: LockService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly dialog: MatDialog
  ) {}

  public ngAfterViewInit() {
    const headerPortal = new TemplatePortal(
      this.headerPortalContent,
      this.viewContainerRef
    );

    this.layoutService.setHeaderPortal(headerPortal);
  }

  public showLockModal() {
    this.lockService.showLockModal();
  }

  public ngOnDestroy(): void {
    this.layoutService.setHeaderPortal(null);
  }

  public openInfoDialog() {
    this.dialog.open<AdminEditModeInfoDialogComponent>(
      AdminEditModeInfoDialogComponent
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, switchMap } from 'rxjs/operators';

import { MdmApiService } from '../mdm-api.service';

import {
  getMdmProjectInfoAction,
  getMdmProjectInfoFailureAction,
  getMdmProjectInfoSuccessAction,
} from './mdm.actions';

@Injectable()
export class MdmEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly mdmApiService: MdmApiService
  ) {}

  public getMdmProjectInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMdmProjectInfoAction),
      distinct((action) => action.payload.mdmNumber),
      switchMap((action) =>
        this.mdmApiService.getMdmProjectInfo(action.payload.mdmNumber).pipe(
          map((payload) =>
            getMdmProjectInfoSuccessAction({
              trigger: action,
              context: 'MdmEffects::getMdmProjectInfo$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getMdmProjectInfoFailureAction({
                trigger: action,
                context: 'MdmEffects::getMdmProjectInfo$',
              })
            )
          )
        )
      )
    )
  );
}

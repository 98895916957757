import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EventLogContext, IGetEventLogResponseItem } from '@models/event-log';

@Injectable({ providedIn: 'root' })
export class EventLogApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getEventLog(context: EventLogContext, id: number) {
    return this.httpClient.get<IGetEventLogResponseItem[]>(
      `{apiUrl}${context}/${id}/log`
    );
  }

  public postComment(
    context: EventLogContext,
    id: number,
    message: {
      message: string;
      projectId?: number;
      scenarioId?: number;
      scenarioCTRId?: number;
      parentId?: number;
    }
  ) {
    return this.httpClient.post<IGetEventLogResponseItem[]>(
      `{apiUrl}${context}/${id}/message`,
      message
    );
  }
}

<ng-container [ngSwitch]="selectedView">
  <ng-container *ngSwitchCase="chartViewType.HOURS">{{
    month?.totalHours | integer : month?.isPeriodEditable
  }}</ng-container>
  <ng-container *ngSwitchCase="chartViewType.CHART">{{
    month?.totalHours | integer : month?.isPeriodEditable
  }}</ng-container>
  <ng-container *ngSwitchCase="chartViewType.FTE">{{
    month?.totalFte | oneDecimal : month?.isPeriodEditable
  }}</ng-container>
</ng-container>

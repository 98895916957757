import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  selectAllClients,
  selectClientsMap,
} from '@collections/clients/store/clients.selectors';
import {
  selectAllActiveEntities,
  selectAllActiveEntitiesMap,
} from '@collections/entities/store/entities.selectors';
import {
  selectAllProjectStages,
  selectProjectStagesMap,
} from '@collections/project-stages/store/project-stages.selectors';
import { selectProjectAvailableStatuses } from '@collections/project-statuses/store/project-statuses.selectors';
import {
  selectAllProjectTypes,
  selectProjectTypesMap,
} from '@collections/project-types/store/project-types.selectors';
import {
  selectAllProjectNames,
  selectAllProjectNamesMap,
} from '@collections/projects-names/store/projects-names.selectors';
import {
  selectAllPxDsWithMasterdata,
  selectPxDsMap,
} from '@collections/pxds/store/pxds.selectors';
import {
  selectAllEngineeringPackageOwners,
  selectAllEngineeringPackageOwnersMap,
  selectAllProjectCreators,
  selectAllProjectCreatorsAndRequestorsMap,
  selectCtrOwners,
  selectCtrOwnersAsRecord,
} from '@collections/users/store/users.selectors';
import {
  ConditionType,
  CtrDueDatePeriods,
  CtrStatus,
  SortType,
} from '@common/enums';
import { UserRole } from '@core/store/core.reducer';
import { selectCurrentRouteUserRole } from '@core/store/core.selectors';
import { IFilterCondition } from '@models/filters';

import { filtersByConditionsFormConfiguration } from './filters-form.config';
import { IFiltersCollectionState } from './filters.reducer';

const ctrStatuses = [
  CtrStatus.IN_PROGRESS,
  CtrStatus.NOT_STARTED,
  CtrStatus.COMPLETED,
  CtrStatus.CANCELLED,
];

const ctrDueDatePeriods = [
  CtrDueDatePeriods.THIS_WEEK,
  CtrDueDatePeriods.THIS_MONTH,
  CtrDueDatePeriods.NEXT_WEEK,
  CtrDueDatePeriods.NEXT_MONTH,
];

const sortByNameAsc = (a, b) =>
  a.name > b.name ? 1 : a.name < b.name ? -1 : 0;

/**
 * Provides maps for extracting information about filters autocompletion.
 * Used for retriving conditions autocompletion labels.
 */
export const selectFilterFormAutocompletionData = createSelector(
  selectAllProjectCreatorsAndRequestorsMap,
  selectProjectAvailableStatuses,
  selectProjectStagesMap,
  selectAllActiveEntitiesMap,
  selectClientsMap,
  selectProjectTypesMap,
  selectPxDsMap,
  selectAllEngineeringPackageOwnersMap,
  selectAllProjectNamesMap,
  selectCtrOwnersAsRecord,
  (
    projectCreators,
    projectStatuses,
    projectStages,
    entities,
    clients,
    projectTypes,
    pxds,
    engineeringPackageOwners,
    projectNames,
    ctrOwners
  ) => ({
    [ConditionType.Requestor]: projectCreators,
    [ConditionType.Client]: clients,
    [ConditionType.ProjectType]: projectTypes,
    [ConditionType.ProjectStatus]: projectStatuses,
    [ConditionType.ProjectStage]: projectStages,
    [ConditionType.Entity]: entities,
    [ConditionType.ScenarioEntity]: entities,
    [ConditionType.PxD]: pxds,
    [ConditionType.CtrStatus]: ctrStatuses,
    [ConditionType.CtrOwner]: ctrOwners,
    [ConditionType.DelegatedFrom]: engineeringPackageOwners,
    [ConditionType.DelegatedTo]: engineeringPackageOwners,
    [ConditionType.Project]: projectNames,
    [ConditionType.CtrDueDate]: ctrDueDatePeriods,
    [ConditionType.CtrEntity]: entities,
    [ConditionType.Supporter]: engineeringPackageOwners,
    [ConditionType.SupportingEntity]: entities,
  })
);

export const selectProjectsAdvacedSearchAutocompletionOptions = createSelector(
  selectAllProjectCreators,
  selectProjectAvailableStatuses,
  selectAllProjectStages,
  selectAllActiveEntities,
  selectAllClients,
  selectAllProjectTypes,
  selectAllPxDsWithMasterdata,
  selectAllEngineeringPackageOwners,
  selectAllProjectNames,
  selectCtrOwners,
  (
    projectCreators,
    projectStatuses,
    projectStages,
    entities,
    clients,
    projectTypes,
    pxds,
    engineeringPackageOwners,
    projectNames,
    ctrOwners
  ) => ({
    [ConditionType.Requestor]: filtersByConditionsFormConfiguration[
      ConditionType.Requestor
    ]
      .autocompleteValue(projectCreators)
      .sort(sortByNameAsc),
    [ConditionType.Client]:
      filtersByConditionsFormConfiguration[
        ConditionType.Client
      ].autocompleteValue(clients),
    [ConditionType.ProjectStatus]:
      filtersByConditionsFormConfiguration[
        ConditionType.ProjectStatus
      ].autocompleteValue(projectStatuses),
    [ConditionType.ProjectStage]:
      filtersByConditionsFormConfiguration[
        ConditionType.ProjectStage
      ].autocompleteValue(projectStages),
    [ConditionType.ProjectType]:
      filtersByConditionsFormConfiguration[
        ConditionType.ProjectType
      ].autocompleteValue(projectTypes),
    [ConditionType.Entity]:
      filtersByConditionsFormConfiguration[
        ConditionType.Entity
      ].autocompleteValue(entities),
    [ConditionType.ScenarioEntity]:
      filtersByConditionsFormConfiguration[
        ConditionType.ScenarioEntity
      ].autocompleteValue(entities),
    [ConditionType.PxD]:
      filtersByConditionsFormConfiguration[ConditionType.PxD].autocompleteValue(
        pxds
      ),
    [ConditionType.CtrStatus]:
      filtersByConditionsFormConfiguration[
        ConditionType.CtrStatus
      ].autocompleteValue(ctrStatuses),
    [ConditionType.CtrOwner]:
      filtersByConditionsFormConfiguration[
        ConditionType.CtrOwner
      ].autocompleteValue(ctrOwners),
    [ConditionType.Supporter]: filtersByConditionsFormConfiguration[
      ConditionType.Supporter
    ]
      .autocompleteValue(engineeringPackageOwners)
      .sort(sortByNameAsc),
    [ConditionType.DelegatedFrom]: filtersByConditionsFormConfiguration[
      ConditionType.DelegatedFrom
    ]
      .autocompleteValue(engineeringPackageOwners)
      .sort(sortByNameAsc),
    [ConditionType.DelegatedTo]: filtersByConditionsFormConfiguration[
      ConditionType.DelegatedTo
    ]
      .autocompleteValue(engineeringPackageOwners)
      .sort(sortByNameAsc),
    [ConditionType.Project]: filtersByConditionsFormConfiguration[
      ConditionType.Project
    ]
      .autocompleteValue(projectNames)
      .sort(sortByNameAsc),
    [ConditionType.CtrDueDate]:
      filtersByConditionsFormConfiguration[
        ConditionType.CtrDueDate
      ].autocompleteValue(ctrDueDatePeriods),
    [ConditionType.CtrEntity]:
      filtersByConditionsFormConfiguration[
        ConditionType.CtrEntity
      ].autocompleteValue(entities),
    [ConditionType.SupportingEntity]:
      filtersByConditionsFormConfiguration[
        ConditionType.SupportingEntity
      ].autocompleteValue(entities),
  })
);

export class FiltersFormSelectors {
  constructor(private readonly feature: string) {}

  public selectFiltersCollectionState =
    createFeatureSelector<IFiltersCollectionState>(this.feature);

  public selectFilterConditions = createSelector(
    this.selectFiltersCollectionState,
    ({ currentFilter }) =>
      currentFilter?.conditions ? currentFilter.conditions : []
  );

  public selectSortType = createSelector(
    this.selectFiltersCollectionState,
    ({ currentFilter }) =>
      !!currentFilter && currentFilter.sortType
        ? currentFilter.sortType
        : SortType.CreatedNewest
  );

  public selectProjectsAdvancedSearchFormData = createSelector(
    this.selectFilterConditions,
    this.selectSortType,
    selectFilterFormAutocompletionData,
    (serverFilterData: IFilterCondition[], sortType: SortType, context) => {
      const emptyForm = {
        sortType,
      };

      for (const type in ConditionType) {
        if (filtersByConditionsFormConfiguration[type]) {
          const { formControlName, initialValue = null } =
            filtersByConditionsFormConfiguration[type];

          emptyForm[formControlName] = initialValue;
        }
      }

      return serverFilterData.reduce((formData, condition) => {
        const formConditionConfiguration =
          filtersByConditionsFormConfiguration[condition.type] || null;

        if (!formConditionConfiguration) {
          return formData;
        }
        const allowOtherValues =
          filtersByConditionsFormConfiguration[condition.type].allowOtherValues;

        return {
          ...formData,
          [formConditionConfiguration.formControlName]:
            formConditionConfiguration.convertServerToFormData(
              condition,
              context,
              allowOtherValues
            ),
        };
      }, emptyForm);
    }
  );
}

export const filtersFormSelectors = {
  [UserRole.REQUESTOR]: new FiltersFormSelectors('requestor-filters'),
  [UserRole.ENGINEER]: new FiltersFormSelectors('engineer-filters'),
};

const getFormFiltersSelectorsByContext = (userRole: UserRole) =>
  [UserRole.REQUESTOR, UserRole.ENGINEER].includes(userRole)
    ? (filtersFormSelectors[userRole] as FiltersFormSelectors)
    : null;

export const selectProjectsAdvancedSearchFormDataFactory = (store: Store) =>
  store.select(selectCurrentRouteUserRole).pipe(
    switchMap((userRoleContext) => {
      const selectors = getFormFiltersSelectorsByContext(userRoleContext);

      return selectors
        ? store.select(selectors.selectProjectsAdvancedSearchFormData)
        : EMPTY;
    })
  );

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IMdmProjectResponse {
  mdmNumber: string;
  localProject: { label: string; value: string }[];
  groupProject: { label: string; value: string }[];
}

function convertKey(key: string) {
  return key
    .replace(/([A-Z])/g, ' $1')
    .replace(/mdm/gi, 'MDM')
    .replace(/id/gi, 'ID');
}

@Injectable({
  providedIn: 'root',
})
export class MdmApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getMdmProjectInfo(mdmNumber: string) {
    if (mdmNumber === '')
      return of({ mdmNumber, localProject: [], groupProject: [] });

    return this.httpClient
      .get<IMdmProjectResponse>(`{apiUrl}projects/mdm/${mdmNumber}`)
      .pipe(
        map((data) =>
          data
            ? {
                mdmNumber,
                localProject: Object.entries(data.localProject).reduce(
                  (r, [k, v]) => [...r, { label: convertKey(k), value: v }],
                  []
                ),
                groupProject: Object.entries(data.groupProject).reduce(
                  (r, [k, v]) =>
                    ['sapProjects'].includes(k)
                      ? r
                      : [...r, { label: convertKey(k), value: v }],
                  []
                ),
              }
            : { mdmNumber, localProject: [], groupProject: [] }
        ),
        catchError((err) => {
          if (err?.status != 404) {
            return throwError(err);
          }
          return of(null);
        })
      );
  }
}

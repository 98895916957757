import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IFilter } from '@models/filters';

@Injectable({ providedIn: 'root' })
export class FiltersApiService {
  constructor(private readonly httpClient: HttpClient) {}

  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public getFilters() {
    return this.httpClient.get<IFilter[]>('{apiUrl}users/filter');
  }

  /**
   * Upserts filter.
   */
  public postFilter(filter: IFilter) {
    return this.httpClient.post('{apiUrl}users/filter', filter);
  }

  public deleteFilter(filterId: number) {
    const options = {
      headers: this.headers,
      body: {
        id: filterId,
      },
    };
    return this.httpClient.delete('{apiUrl}users/filter', options);
  }
}

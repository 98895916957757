import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import { getMdmProjectInfoAction } from '@collections/mdm/store/mdm.actions';
import { selectMdmProjectInfoByMdmNumber } from '@collections/mdm/store/mdm.selectors';

@Component({
  selector: 'app-mdm-project-info',
  templateUrl: './mdm-project-info.component.html',
  styleUrls: ['./mdm-project-info.component.scss'],
})
export class MdmProjectInfoComponent {
  constructor(private readonly store: Store) {}

  public showNonEmptyFormControl = new FormControl<boolean>(true);

  @Input() public title: string;

  private readonly mdmNumber$ = new ReplaySubject<string>(1);

  @Input() public set mdmNumber(mdmNumber: string) {
    this.mdmNumber$.next(mdmNumber);
  }

  public loading = false;

  public data$ = combineLatest([
    this.mdmNumber$.pipe(debounceTime(1000)),
    this.showNonEmptyFormControl.valueChanges.pipe(
      startWith(this.showNonEmptyFormControl.value)
    ),
  ]).pipe(
    switchMap(([mdmNumber, showNoneEmpty]: [string, boolean]) => {
      this.store.dispatch(
        getMdmProjectInfoAction({
          payload: { mdmNumber },
        })
      );

      return this.store.select(selectMdmProjectInfoByMdmNumber(mdmNumber)).pipe(
        map((data) =>
          !data
            ? {
                localProject: [],
                groupProject: [],
              }
            : {
                localProject: this.filter(data.localProject, !showNoneEmpty),
                groupProject: this.filter(data.groupProject, !showNoneEmpty),
              }
        )
      );
    })
  );

  public filter(data, showEmpty) {
    if (showEmpty) {
      return data;
    }

    return !data ? [] : data.filter((item) => !!item.value);
  }
}

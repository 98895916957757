import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Application } from '@models/application';

@Injectable({ providedIn: 'root' })
export class ApplicationsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getApplications() {
    return this.httpClient.get<Application[]>('{apiUrl}applications');
  }
}

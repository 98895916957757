import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest, filter } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { getEventLogAction } from '@collections/event-log/store/event-log.actions';
import { selectEventLogFactory } from '@collections/event-log/store/event-log.selectors';
import { selectCurrentUser } from '@collections/users/store/users.selectors';
import {
  selectCurrentRouteParams,
  selectEventLogContext,
} from '@core/store/core.selectors';

const createSignature = (userName) =>
  [...userName.matchAll(/(^|\s)[a-zA-Z]/g)]
    .map(([x]) => x.trim())
    .join('')
    .slice(0, 2)
    .toUpperCase();

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss'],
})
export class EventLogComponent implements OnInit, OnDestroy {
  constructor(private readonly store: Store) {}

  private readonly eventLogContext$ = this.store.select(selectEventLogContext);

  private readonly currentRouteParams$ = this.store
    .select(selectCurrentRouteParams)
    .pipe(
      tap((filters) => {
        this.setFilter({
          type: [0, 1],
          scenarioId: filters.scenarioId
            ? parseInt(filters.scenarioId, 10)
            : null,
          scenarioCTRId:
            typeof filters.ctrId !== 'undefined'
              ? [parseInt(filters.ctrId, 10)]
              : [],
        });
      }),
      shareReplay(1)
    );

  public breadcrumb$ = this.currentRouteParams$.pipe(
    map((params) => ({
      projectId: params.projectId,
      scenarioId: params.scenarioId,
      ctrId: params.ctrId,
    }))
  );

  public replyTo = null;

  private readonly subscription = new Subscription();

  public author$ = this.store
    .select(selectCurrentUser)
    .pipe(map((user) => user.fullName));

  public avatar$ = this.store
    .select(selectCurrentUser)
    .pipe(map((user) => user.email));

  public signature$ = this.author$.pipe(
    map((userName) => createSignature(userName))
  );

  public showFilters = false;

  private readonly filterSubject$ = new BehaviorSubject<{
    scenarioCTRId?: number[];
    scenarioId?: number;
    type?: number[];
  }>({});

  public filter$ = this.filterSubject$.pipe(shareReplay(1));

  public messages$ = combineLatest([
    this.currentRouteParams$,
    this.eventLogContext$.pipe(filter((v) => !!v)),
  ]).pipe(
    switchMap(([params, context]) =>
      combineLatest([
        this.store.select(
          selectEventLogFactory(context.keyName, params[context.routeParamName])
        ),
        this.filter$,
      ])
    ),
    map(([events, filter]) =>
      events.filter((event) => {
        if (
          typeof filter.type !== 'undefined' &&
          filter.type.length > 0 &&
          !filter.type.includes(event.logType)
        ) {
          return false;
        }
        if (!!filter.scenarioId && filter.scenarioId != event.scenarioId) {
          return false;
        }
        if (
          filter.scenarioCTRId?.length > 0 &&
          !!event.scenarioCtrId &&
          !filter.scenarioCTRId.includes(event.scenarioCtrId)
        ) {
          return false;
        }
        return true;
      })
    ),
    map((events) =>
      events
        .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
        .reduce(
          (r, event) => [
            ...r,
            {
              id: event.eventId,
              message: event.message,
              signature: createSignature(event.createdBy),
              author: event.createdBy,
              date: new Date(event.creationDate + '+00:00'),
              type: event.logType === 1 ? 'comment' : 'system',
              reply: false,
            },
            ...event.responses.map((response) => ({
              id: event.eventId,
              message: response.message,
              signature: createSignature(response.createdBy),
              author: response.createdBy,
              date: new Date(response.creationDate + '+00:00'),
              type: 'comment',
              reply: true,
            })),
          ],
          []
        )
    ),
    tap(() => {
      this.replyTo = null;
    })
  );

  public hasFilters$ = combineLatest(this.filter$, this.breadcrumb$).pipe(
    map((filter, breadcrumbs) => this.hasFilters(filter, breadcrumbs))
  );

  public ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.currentRouteParams$,
        this.eventLogContext$.pipe(filter((v) => !!v)),
      ])
        .pipe(filter(([params, context]) => !!params[context.keyName]))
        .subscribe(([params, context]) => {
          this.store.dispatch(
            getEventLogAction({
              context: 'EventLogComponent::ngOnInit',
              payload: { context: context.name, id: params[context.keyName] },
            })
          );
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setFilter(filters) {
    this.filterSubject$.next(filters);
  }

  private hasFilters(filter, breadcrumbs) {
    return (
      (typeof filter.type !== 'undefined' && filter.type.length === 1) ||
      (!!filter.scenarioId && filter.scenarioId !== breadcrumbs.scenarioId) ||
      (!!filter.scenarioCTRId &&
        !!breadcrumbs.ctrId &&
        (filter.scenarioCTRId?.length !== 1 ||
          filter.scenarioCTRId.includes(breadcrumbs.ctrId)))
    );
  }
}

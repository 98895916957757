import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { MediatorService } from '@app/mediator.service';
import {
  CompareScenarioResponse,
  ScenariosForComparisonQuery,
} from '@app/models/backendModel';
import {
  getScenarioComparisonAction,
  getScenarioComparisonFailureAction,
  getScenarioComparisonSuccessAction,
} from './scenario-comparison.actions';
import { selectScenarioComparisionItemExistsForProjectFactory } from './scenario-comparison.selectors';

@Injectable()
export class ScenarioComparisonEffects {
  public getProjectScenarioComparisonItemById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getScenarioComparisonAction),
      switchMap((action) =>
        this.mediator
          .send<ScenariosForComparisonQuery, CompareScenarioResponse[]>(
            'ScenariosForComparisonQuery',
            {
              projectId: Number(action.payload.projectId),
            }
          )
          .pipe(
            withLatestFrom(
              this.store.select(
                selectScenarioComparisionItemExistsForProjectFactory(
                  action.payload.projectId
                )
              )
            ),
            map(
              ([
                projectScenarioComparisonItem,
                scenarioComparisonItemExists,
              ]) => {
                if (!scenarioComparisonItemExists) {
                  return getScenarioComparisonSuccessAction({
                    trigger: action,
                    context:
                      'ScenarioComparison:getProjectScenarioComparisonItemById$',
                    payload: {
                      projectId: action.payload.projectId,
                      scenarioComparisonItems: projectScenarioComparisonItem,
                    },
                  });
                }
              }
            ),
            catchError(() =>
              of(
                getScenarioComparisonFailureAction({
                  trigger: action,
                  context:
                    'ScenarioComparison:getProjectScenarioComparisonItemById$',
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly mediator: MediatorService
  ) {}
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AdminBlock } from '@models/admin-block';
import { AdminScope } from '@models/admin-scope';

import { MasterdataTableService } from '../masterdata-table.service';
import {
  getAdminBlockScopesAction,
  openAddScopeAction,
  openCopyBlockAction,
  openEditBlockAction,
  openReorderBlockScopesAction,
  requestRemoveBlockAction,
} from '../store/admin-activities.actions';
import { selectAdminScopesByBlockIdFactory } from '../store/admin-activities.selectors';

@Component({
  selector: 'app-masterdata-block',
  templateUrl: './masterdata-block.component.html',
  styleUrls: [
    './masterdata-block.component.scss',
    '../masterdata-table/masterdata-table.shared.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterdataBlockComponent implements OnChanges {
  @Input() data: AdminBlock;

  public scopes$: Observable<AdminScope[]>;

  constructor(
    public readonly tableService: MasterdataTableService,
    private readonly store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      (changes.data.firstChange ||
        changes.data.currentValue.blockId !==
          changes.data.previousValue.blockId)
    ) {
      this.store.dispatch(
        getAdminBlockScopesAction({ payload: { blockId: this.data.blockId } })
      );

      this.scopes$ = this.store
        .select(selectAdminScopesByBlockIdFactory(this.data.blockId))
        .pipe(shareReplay(1));
    }
  }

  public openBlockEdit() {
    this.store.dispatch(
      openEditBlockAction({
        context: 'MasterdataBlockComponent::openBlockEdit',
        payload: {
          blockId: this.data.blockId,
        },
      })
    );
  }

  public removeBlock() {
    this.store.dispatch(
      requestRemoveBlockAction({
        context: 'MasterdataBlockComponent::removeBlock',
        payload: this.data,
      })
    );
  }

  public openAddScope() {
    this.store.dispatch(
      openAddScopeAction({
        context: 'MasterdataBlockComponent::openAddScope',
        payload: {
          blockId: this.data.blockId,
        },
      })
    );
  }

  public openCopyBlock() {
    this.store.dispatch(
      openCopyBlockAction({
        context: 'MasterdataBlockComponent::openCopyBlock',
        payload: {
          blockId: this.data.blockId,
        },
      })
    );
  }

  public openReorderScopes() {
    this.store.dispatch(
      openReorderBlockScopesAction({
        context: 'MasterdataBlockComponent::openReorderScopes',
        payload: {
          blockId: this.data.blockId,
        },
      })
    );
  }

  public trackByScope(index: number, scope: AdminScope) {
    return scope.scopeId;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { AdminActivity } from '@models/admin-activity';

import { activityQtyValueOptions } from '../edit-activity-form/edit-activity-form.component';
import { MasterdataTableService } from '../masterdata-table.service';
import {
  openCopyActivityAction,
  openEditActivityAction,
  requestRemoveActivityAction,
} from '../store/admin-activities.actions';

const mapQtyValueLabelMap = Object.values(activityQtyValueOptions).reduce(
  (r, { id, label }) => ({ ...r, [id]: label }),
  {}
);

@Component({
  selector: 'app-masterdata-activity',
  templateUrl: './masterdata-activity.component.html',
  styleUrls: [
    './masterdata-activity.component.scss',
    '../masterdata-table/masterdata-table.shared.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterdataActivityComponent {
  @Input() data: AdminActivity;

  public mapQtyValueLabelMap = mapQtyValueLabelMap;

  constructor(
    public readonly tableService: MasterdataTableService,
    private readonly store: Store
  ) {}

  public openActivityEdit() {
    this.store.dispatch(
      openEditActivityAction({
        context: 'MasterdataActivityComponent:openActivityEdit',
        payload: {
          activityId: this.data.id,
        },
      })
    );
  }

  public openCopyActivity() {
    this.store.dispatch(
      openCopyActivityAction({
        context: 'MasterdataActivityComponent:openCopyActivity',
        payload: {
          activityId: this.data.id,
        },
      })
    );
  }

  public removeActivity() {
    this.store.dispatch(
      requestRemoveActivityAction({
        context: 'MasterdataActivityComponent:removeActivity',
        payload: this.data,
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';

import { ProjectTypesApiService } from '../project-types-api.service';

import {
  getProjectTypesAction,
  getProjectTypesFailureAction,
  getProjectTypesSuccessAction,
} from './project-types.actions';

@Injectable()
export class ProjectTypesEffects {
  public getProjectTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectTypesAction),
      switchMap((action) =>
        this.projectTypesApiService.getProjectTypes().pipe(
          map((payload) =>
            getProjectTypesSuccessAction({
              trigger: action,
              context: 'ProjectTypesEffects::getProjectTypes$',
              payload,
            })
          ),
          catchError(() =>
            of(
              getProjectTypesFailureAction({
                trigger: action,
                context: 'ProjectTypesEffects::getProjectTypes$',
              })
            )
          )
        )
      )
    )
  );

  public initProjectTypesData$ = createEffect(() =>
    this.store.select(selectCurrentInitializedUser).pipe(
      filter((user) => !!user),
      take(1),
      switchMap(() => [
        getProjectTypesAction({
          context: 'ProjectTypesEffects::initProjectTypesData$',
        }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly projectTypesApiService: ProjectTypesApiService,
    private readonly store: Store
  ) {}
}

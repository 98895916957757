import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlockAssignsDto, RoleAssignsDto } from '@app/models/backendModel';

import { EngineeringRoleType } from '@common/enums';
import { LiveSavingInterceptorService } from '@common/live-saving-interceptor.service';
import { DelegationInfo } from '@models/delegation-info';
import { Observable } from 'rxjs';

export interface UpdateRolesPercentageRoleAssignmentDto {
  assignmentId: number;
  roleType: EngineeringRoleType;
  roles: RoleAssignsDto[];
}
export interface UpdateRolesPercentageDto {
  roleAssignments: UpdateRolesPercentageRoleAssignmentDto[];
}

@Injectable({ providedIn: 'root' })
export class EquipAssignsApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly liveSavingInterceptorService: LiveSavingInterceptorService
  ) {}

  public getEquipAssigns(
    ctrId: number,
    equipmentId: number
  ): Observable<BlockAssignsDto[]> {
    return this.httpClient.get<BlockAssignsDto[]>(
      `{apiUrl}scenarioCTRs/${ctrId}/equipAssigns/${equipmentId}`
    );
  }

  public updateRolesPercentage(
    ctrId: number,
    payload: UpdateRolesPercentageDto
  ): Observable<object> {
    return this.liveSavingInterceptorService.put(
      `{apiUrl}scenarioCTRs/${ctrId}/assigns`,
      payload
    );
  }

  public getDelegationInfo(ctrId: number): Observable<DelegationInfo> {
    return this.httpClient.get<DelegationInfo>(
      `{apiUrl}scenarioCTRs/${ctrId}/delegationInfo`
    );
  }

  public cancelRequest(ctrId: number, scopeId: number): Observable<void> {
    return this.httpClient.put<void>(
      `{apiUrl}scenarioCTRs/${ctrId}/assign/${scopeId}/cancel`,
      {}
    );
  }

  public updateComment(
    ctrId: number,
    scopeId: number,
    payload: { comment: string }
  ): Observable<object> {
    return this.liveSavingInterceptorService.put(
      `{apiUrl}scenarioCTRs/${ctrId}/assign/${scopeId}/comment`,
      payload
    );
  }
}

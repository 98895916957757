import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISidenavState } from './store/sidenav.reducer';

@Injectable({ providedIn: 'root' })
export class SidenavApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getNavBarDetails(id: number, search = false) {
    return this.httpClient.get<ISidenavState>(
      `{apiUrl}scenarioCTRs/${id}/navbarDetails`,
      {
        params: { search: search.toString() },
      }
    );
  }
}

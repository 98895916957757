import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { delay, map, startWith, switchMap, tap } from 'rxjs/operators';

import { selectSidenavScenarios } from '@app/layout/sidenav/store/sidenav.selectors';
import { getScenarioAction } from '@collections/scenarios/store/scenarios.actions';
import { selectScenarioCTRsFactory } from '@collections/scenarios/store/scenarios.selectors';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  constructor(public readonly store: Store) {}

  @Input() filter: {
    scenarioId: number;
    scenarioCtrId: number;
    type: number[];
  };

  @Output() updateFilter = new EventEmitter<{
    scenarioId: number;
    scenarioCTRId: number[];
    type: number[];
  }>();

  public commentFormGroup = new FormGroup({
    type: new FormGroup({ 0: new FormControl(), 1: new FormControl() }),
    scenarioId: new FormControl(),
    scenarioCTRId: new FormControl(),
  });

  public scenarios$ = this.store.select(selectSidenavScenarios).pipe(
    tap(() => {
      this.commentFormGroup.patchValue(
        {
          scenarioId: this.filter.scenarioId,
        },
        { onlySelf: true, emitEvent: false }
      );
    })
  );

  public ctrs$ = this.commentFormGroup.get('scenarioId').valueChanges.pipe(
    startWith(this.commentFormGroup.get('scenarioId').value),
    distinctUntilChanged(),
    switchMap((scenarioId) => {
      if (scenarioId) {
        this.store.dispatch(
          getScenarioAction({
            context: 'FilterComponent::ctrs$',
            payload: { scenarioId },
          })
        );
      }

      return this.store.select(selectScenarioCTRsFactory(scenarioId));
    }),
    tap(() => {
      this.commentFormGroup.patchValue(
        {
          scenarioCTRId: this.filter.scenarioCtrId,
        },
        { onlySelf: true, emitEvent: false }
      );
    }),
    delay(500)
  );

  private subscriptions = new Subscription();

  public ngOnInit(): void {
    this.subscriptions = this.commentFormGroup.valueChanges
      .pipe(
        map((value) => ({
          ...value,
          scenarioCTRId: value.scenarioCTRId
            ? value.scenarioCTRId.filter((x) => x !== null)
            : [],
        })),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.updateFilter.emit({
          scenarioId: parseInt(value.scenarioId, 10),
          scenarioCTRId: value.scenarioCTRId
            ? value.scenarioCTRId.map((v) => parseInt(v, 10))
            : [],
          type: Object.entries(value.type)
            .filter(([k, v]) => v)
            .map(([k]) => parseInt(k, 10)),
        });
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.filter.currentValue.scenarioId !==
        changes.filter.previousValue?.scenarioId ||
      JSON.stringify(changes.filter.currentValue.scenarioCTRId) !==
        JSON.stringify(changes.filter.previousValue?.scenarioCTRId)
    ) {
      this.commentFormGroup.patchValue(
        {
          type: {
            0: this.filter.type?.indexOf(0) >= 0,
            1: this.filter.type?.indexOf(1) >= 0,
          },
          scenarioId: this.filter.scenarioId,
          scenarioCTRId: this.filter.scenarioCtrId,
        },
        { onlySelf: false, emitEvent: true }
      );
    }
  }

  public ngAfterViewInit() {
    this.commentFormGroup.patchValue(
      {
        type: {
          0: this.filter.type?.indexOf(0) >= 0,
          1: this.filter.type?.indexOf(1) >= 0,
        },
        scenarioId: this.filter.scenarioId,
        scenarioCTRId: this.filter.scenarioCtrId,
      },
      { onlySelf: false, emitEvent: true }
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

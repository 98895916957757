import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CompareScenarioResponse } from '@app/models/backendModel';
import { getScenarioComparisonSuccessAction } from './scenario-comparison.actions';

export const FEATURE_KEY = 'scenario-comparison';
export interface ProjectScenarioComparison {
  projectId: number;
  scenarioComparisonItems: CompareScenarioResponse[];
}
export interface IScenarioComparisonCollectionState
  extends EntityState<ProjectScenarioComparison> {
  loaded: boolean;
}

export const scenarioComparisonEntityAdapter =
  createEntityAdapter<ProjectScenarioComparison>({
    selectId: ({ projectId }) => projectId,
  });

export const initialState = scenarioComparisonEntityAdapter.getInitialState({
  loaded: false,
} as IScenarioComparisonCollectionState);

export const reducer = createReducer(
  initialState,
  on(getScenarioComparisonSuccessAction, (state, { payload }) =>
    scenarioComparisonEntityAdapter.upsertOne(payload, { ...state })
  )
);

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';

import { LoadingStateEnum } from '@collections/state-commons';
import { UserRole } from '@core/store/core.reducer';

import { getProjectAction } from './store/projects.actions';
import { projectsSelectors } from './store/projects.selectors';

@Injectable({ providedIn: 'root' })
export class RequestorProjectResolver {
  constructor(private readonly store: Store) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const projectId = parseInt(route.params.projectId, 10);
    return this.store
      .select(
        projectsSelectors[UserRole.REQUESTOR].selectProjectLoadStateByIdFactory(
          projectId
        )
      )
      .pipe(
        switchMap((loadingState) => {
          if (loadingState === LoadingStateEnum.NOT_STARTED) {
            this.store.dispatch(
              getProjectAction({
                context: 'ProjectResolver::resolve',
                payload: { projectId, context: UserRole.REQUESTOR },
              })
            );
          }

          return this.store
            .select(
              projectsSelectors[UserRole.REQUESTOR].selectProjectByIdFactory(
                projectId
              )
            )
            .pipe(filter((v) => !!v));
        }),
        take(1)
      );
  }
}

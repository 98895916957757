import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ScenarioSummaryCTRsApiService } from '../scenario-summary-ctrs-api.service';

import {
  getScenarioSummaryCTRsAction,
  getScenarioSummaryCTRsFailureAction,
  getScenarioSummaryCTRsSuccessAction,
} from './scenario-summary-ctrs.actions';

@Injectable()
export class ScenarioSummaryCTRsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly scenarioSummaryCTRsApiService: ScenarioSummaryCTRsApiService
  ) {}

  public getScenarioSummaryCTRs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getScenarioSummaryCTRsAction),
      switchMap((action) =>
        this.scenarioSummaryCTRsApiService
          .getScenarioSummaryCTRs(action.payload.scenarioId)
          .pipe(
            map((payload) =>
              getScenarioSummaryCTRsSuccessAction({
                trigger: action,
                context: 'ScenarioSummaryCTRsEffects::getScenarioSummaryCTRs$',
                payload,
              })
            ),
            catchError(() =>
              of(
                getScenarioSummaryCTRsFailureAction({
                  trigger: action,
                  context:
                    'ScenarioSummaryCTRsEffects::getScenarioSummaryCTRs$',
                })
              )
            )
          )
      )
    )
  );
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  openEditActivityAction,
  openEditBlockAction,
  openEditScopeAction,
} from '../../admin-activities/store/admin-activities.actions';

@Component({
  selector: 'app-change-log-table',
  templateUrl: './change-log-table.component.html',
  styleUrls: ['./change-log-table.component.scss'],
})
export class ChangeLogTableComponent {
  @Input() public changesList: any[];

  @Input() public readonly: boolean;

  @Output() public close = new EventEmitter<void>();

  constructor(private readonly store: Store) {}

  public editActivity(activityId: number) {
    this.store.dispatch(
      openEditActivityAction({
        context: 'ChangeLogTableComponent::editActivity',
        payload: {
          activityId,
        },
      })
    );

    this.close.emit();
  }

  public editScope(scopeId: number) {
    this.store.dispatch(
      openEditScopeAction({
        context: 'ChangeLogTableComponent::editScope',
        payload: {
          scopeId,
        },
      })
    );

    this.close.emit();
  }

  public editBlock(blockId: number) {
    this.store.dispatch(
      openEditBlockAction({
        context: 'ChangeLogTableComponent::editBlock',
        payload: {
          blockId,
        },
      })
    );

    this.close.emit();
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { CtrStatus } from '@common/enums';

@Component({
  selector: 'app-ctr-status-icon',
  templateUrl: './ctr-status-icon.component.html',
  styleUrls: ['./ctr-status-icon.component.css'],
})
export class CtrStatusIconComponent implements OnInit {
  @Input() ctrStatus: CtrStatus;
  statusClass: string;

  ngOnInit(): void {
    this.statusClass = this.getCtrStatusClass(this.ctrStatus);
  }

  getCtrStatusClass(ctrStatus: CtrStatus): string {
    switch (ctrStatus) {
      case CtrStatus.COMPLETED: {
        return 'completed-status';
      }
      case CtrStatus.IN_PROGRESS: {
        return 'inprogress-status';
      }
      case CtrStatus.CANCELLED: {
        return 'cancelled-status';
      }
      case CtrStatus.NOT_STARTED: {
        return 'notstarted-status';
      }
      default: {
        return 'selected-status';
      }
    }
  }
}

import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { IntegerPipe } from '@app/common/pipe/integer.pipe';
import { OneDecimalPipe } from '@app/common/pipe/one-decimal.pipe';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { CalendarizationTableRolesComponent } from '../calendarization-table-roles/calendarization-table-roles.component';
import { CalendarizationForProject } from '../store/ctrs-calendarization.reducer';
import {
  selectCtrFteDistributionForRoles,
  selectCtrHoursDistributionForRoles,
} from '../store/roles-calendarization.selectors';

export interface CalendarizationDetailedDialogData {
  checkedCtr: CalendarizationForProject;
  isRequestorMode: boolean;
}
@Component({
  selector: 'app-calendarization-detailed-view',
  templateUrl: './calendarization-detailed-view.component.html',
  styleUrls: ['./calendarization-detailed-view.component.scss'],
  standalone: true,
  providers: [DecimalPipe],
  imports: [
    MatDialogModule,
    MatCardModule,
    CommonModule,
    MatButtonModule,
    CalendarizationTableRolesComponent,
    IntegerPipe,
    OneDecimalPipe,
  ],
})
export class CalendarizationDetailedViewComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: CalendarizationDetailedDialogData,
    public readonly dialogRef: MatDialogRef<CalendarizationDetailedViewComponent>,
    public readonly store: Store
  ) {}

  public selectedCtrData: CalendarizationForProject;
  public entitiesNames: string;
  public isRequestorMode = this.data.isRequestorMode;
  public totalAmounts$ = combineLatest([
    this.store.select(
      selectCtrFteDistributionForRoles(this.data.checkedCtr.scenarioCTRId)
    ),
    this.store.select(
      selectCtrHoursDistributionForRoles(this.data.checkedCtr.scenarioCTRId)
    ),
  ]).pipe(
    map(([fte, hours]) => {
      const totalHours = hours.reduce((acc, curr) => {
        curr.hoursDistribution.forEach((roleDistribution) => {
          roleDistribution.hoursPeriods.forEach((role) => {
            acc += role.hoursInPeriod;
          });
        });
        return acc;
      }, 0);

      const totalFte = fte.reduce((acc, curr) => {
        curr.fteDistribution.forEach((roleDistribution) => {
          roleDistribution.ftePeriods.forEach((role) => {
            acc += role.fteInPeriod;
          });
        });
        return acc;
      }, 0);
      return { totalHours, totalFte };
    })
  );

  ngOnInit(): void {
    this.selectedCtrData = this.data.checkedCtr;
    this.entitiesNames = this.selectedCtrData.entities.join(', ');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IGetEntitiesResponseItem } from '@models/entities';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntitiesApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getEntities(): Observable<IGetEntitiesResponseItem[]> {
    return this.httpClient.get<IGetEntitiesResponseItem[]>(
      '{apiUrl}entities?getAllEntities=true'
    );
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animationFrameScheduler, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-table-cell-textarea',
  templateUrl: './table-cell-textarea.component.html',
  styleUrls: ['./table-cell-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TableCellTextareaComponent),
    },
  ],
})
export class TableCellTextareaComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input() controlId: string;
  @Input() maxLength: number;
  @Input() counterMinimumValue = 0;
  @Input() counterForFocusedOnly: boolean;
  @Input() disableCounter: boolean;
  @Input() debounce = 500;
  @Input() error: boolean;
  @Input() placeholder: string;
  @Input() fontSize = 'inherit';
  @Input() fitToCell = false;
  @Output() inputEvent: EventEmitter<string> = new EventEmitter<string>();
  public content: string;
  private readonly inputEventEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  private inputEventSubscription: Subscription;
  private animationFrameSubscription: Subscription;

  @ViewChild('textarea', { static: false })
  private readonly textareaElement: ElementRef<HTMLTextAreaElement>;

  private onChange: (value) => void;
  private onTouched: () => void;

  public disabled = false;

  ngOnInit(): void {
    if (!this.fitToCell) {
      this.setInitialTextareaHeight();
    }
    this.inputEventSubscription = this.inputEventEmitter
      .pipe(debounceTime(this.debounce))
      .subscribe((inputValue) => {
        if (!this.fitToCell) {
          this.updateSize();
        }
        this.onChange(inputValue);
        this.inputEvent.emit(inputValue);
      });
  }

  ngAfterViewInit() {
    if (!this.fitToCell) {
      this.updateSize();
    }
  }

  ngOnDestroy(): void {
    this.inputEventSubscription.unsubscribe();
  }

  setInitialTextareaHeight(): void {
    const textareaElement = this.textareaElement?.nativeElement;
    if (textareaElement) {
      textareaElement.setAttribute(
        'style',
        `height: ${textareaElement.scrollHeight}px; overflow-y: hidden;`
      );
    }
  }

  private updateSize() {
    if (this.animationFrameSubscription) {
      this.animationFrameSubscription.unsubscribe();
    }

    this.animationFrameSubscription = animationFrameScheduler.schedule(() => {
      if (!this.disabled) {
        const element = this.textareaElement.nativeElement;

        // todo performance issues may appear for bigger tables using this component - contenteditable attribute may be used for this purpose
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
      }
    });
  }

  onInput(inputEvent: Event): void {
    const textareaElement = inputEvent.target as HTMLTextAreaElement;

    if (textareaElement) {
      const inputValue = textareaElement.value;
      if (inputValue !== '-') {
        this.inputEventEmitter.emit(inputValue);
      }
    }
  }

  onFocus(event): void {
    event.currentTarget.scrollIntoView({ block: 'center' });
  }

  writeValue(value: string) {
    this.content = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { selectAdminBlocksByPxDFactory } from '@admin/admin-activities/store/admin-activities.selectors';

@Injectable()
export class BlockDisplayNameValidator implements AsyncValidator {
  private readonly ctrId$ = new ReplaySubject<number>(1);

  private readonly blockId$ = new ReplaySubject<number>(1);

  constructor(private readonly store: Store) {}

  public validate(
    control: AbstractControl
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    const requestedName = control.value;

    return this.ctrId$.pipe(
      switchMap((ctrId) =>
        this.store.select(selectAdminBlocksByPxDFactory(ctrId))
      ),
      withLatestFrom(this.blockId$),
      map(([blocks, blockOwnId]) =>
        blocks
          .filter(({ blockId }) => blockId !== blockOwnId)
          .map(({ blockDisplayName }) => blockDisplayName)
      ),
      map((blockNames) =>
        blockNames
          .map((b) => b.trim().toLowerCase())
          .includes(requestedName.trim().toLowerCase())
      ),
      map((duplicate) => (duplicate ? { duplicateName: true } : null)),
      take(1)
    );
  }

  public setCtrId(value: number) {
    this.ctrId$.next(value);
  }

  public setBlockId(value: number) {
    this.blockId$.next(value);
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, map, shareReplay, take } from 'rxjs/operators';

import { selectCurrentUserIsAdmin } from '@collections/users/store/users.selectors';
import { environment } from '@environments/environment';

@Injectable()
export class CurrentUserIsAdminGuard {
  private readonly currentUserIsAdmin$ = this.store
    .select(selectCurrentUserIsAdmin)
    .pipe(debounceTime(1000), shareReplay(1));

  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!environment.authentication) {
      return true;
    }

    return this.currentUserIsAdmin$.pipe(
      take(1),
      map((currentUserIsAdmin) => {
        if (currentUserIsAdmin) {
          return true;
        }

        return this.router.parseUrl('/unauthorized');
      })
    );
  }
}

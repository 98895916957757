import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AssumptionsAndExclusionsService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public showNotification(message: string, duration = 2000) {
    return this.snackBar.open(message, null, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}

import { createAction } from '@ngrx/store';

import { contextAwareProps } from '@core/utils/context-aware-props';
import { ProjectScenarioComparison } from './scenario-comparison.reducer';

export const getScenarioComparisonAction = createAction(
  '[Collection ScenarioComparison] ScenariosForComparisonQuery',
  contextAwareProps<{ payload: { projectId: number } }>()
);

export const getScenarioComparisonSuccessAction = createAction(
  '[Collection ScenarioComparison Effect] ScenariosForComparisonQuery SUCCESS',
  contextAwareProps<{ payload: ProjectScenarioComparison }>()
);

export const getScenarioComparisonFailureAction = createAction(
  '[Collection ScenarioComparison Effect] ScenariosForComparisonQuery FAILURE',
  contextAwareProps()
);
